/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  Divider,
  Grid,
  InputAdornment,
  OutlinedInput,
  Popper,
  Skeleton,
  Typography,
} from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports

import InputBase from "@mui/material/InputBase";
// assets
import { IconSearch, IconX } from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBusiness,
  fetchSuggestion,
  setFiltersSearch,
  setLocationDistance,
} from "../../../../redux/Business/BusinessSlice";
import {
  StandaloneSearchBox,
  LoadScript,
  useJsApiLoader,
} from "@react-google-maps/api";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../../Assets/DekhoMarket-01.png";

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: "100%",
  // top: "-55px !important",
  // minHeight: "48px",
  // padding: "0 12px",
  [theme.breakpoints.down("sm")]: {
    // padding: "0 10px",
  },
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 567,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: "10px",
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    [theme.breakpoints.down("lg")]: {
      width: 450,
    },
    [theme.breakpoints.down("md")]: {
      height: "48px",
      width: "100%",
      marginLeft: 4,
      background: "#fff",
    },
  })
);

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
  ({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    "&:hover": {
      background: theme.palette.primary.dark,
      color: theme.palette.primary.light,
    },
  })
);

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }) => {
  const theme = useTheme();

  return (
    <OutlineInputStyle
      id="input-search-header"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder="Search Business"
      startAdornment={
        <InputAdornment position="start">
          <IconSearch
            stroke={1.5}
            size="1rem"
            color={theme.palette.grey[500]}
          />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          {/* <ButtonBase sx={{ borderRadius: "12px" }}>
            <HeaderAvatarStyle variant="rounded">
              <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
            </HeaderAvatarStyle>
          </ButtonBase> */}
          <Box sx={{ ml: 2 }}>
            <ButtonBase sx={{ borderRadius: "12px" }}>
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  background: theme.palette.orange.light,
                  color: theme.palette.orange.dark,
                  "&:hover": {
                    background: theme.palette.orange.dark,
                    color: theme.palette.orange.light,
                  },
                }}
                {...bindToggle(popupState)}
              >
                <IconX stroke={1.5} size="1.3rem" />
                {/* <TuneIcon sx={{ fontSize: "1.3rem" }} /> */}
              </Avatar>
            </ButtonBase>
          </Box>
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ "aria-label": "weight" }}
    />
  );
};

MobileSearch.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  popupState: PopupState,
};

// ==============================|| SEARCH INPUT ||============================== //
const libraries = ["places"];

const SearchSection = () => {
  const theme = useTheme();
  const [value, setValue] = useState(null);
  const [showSuggestions2, setShowSuggestions2] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const suggestionRef = useRef(null);
  const { searchSuggestion } = useSelector((store) => store.business);
  const inputRef = useRef(null);
  const [address, setAddress] = useState("");

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });

  // states for mobile
  const [valueM, setValueM] = useState(null);
  const [showSuggestions2M, setShowSuggestions2M] = useState(false);
  const [timeoutIdM, setTimeoutIdM] = useState(null);
  const suggestionRefM = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handles Input Change Mobile
  const handleInputChangeM = (e) => {
    const inputValue = e.target.value;
    if (inputValue === "") {
      dispatch(setFiltersSearch({ search_id: "" }));
      dispatch(fetchBusiness());
      setShowSuggestions2M(false);
    }
    if (inputValue !== "" && !showSuggestions2M) {
      setShowSuggestions2M(true);
    }
    setValueM(inputValue);
    clearTimeout(timeoutIdM);
    const newTimeoutId = setTimeout(() => {
      dispatch(fetchSuggestion(valueM));
    }, 500);
    setTimeoutIdM(newTimeoutId);
  };

  // Handles Search Suggestion Mobile
  const handleSearchSuggestionM = (id, name) => {
    setValueM(name);
    const newFilter = { search_id: id };
    dispatch(setFiltersSearch(newFilter));
    dispatch(fetchBusiness());
    setShowSuggestions2M(false);
    navigate("/businesses");
  };

  // Function to hide the search suggestion when click outside
  useEffect(() => {
    // Function to close suggestions when clicked outside
    const handleClickOutside = (event) => {
      if (
        suggestionRefM.current &&
        !suggestionRefM.current.contains(event.target)
      ) {
        setShowSuggestions2M(false);
      }
    };

    // Add event listener to document body
    document.body.addEventListener("click", handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Handles for Search Location
  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setAddress(place.formatted_address);
      dispatch(
        setLocationDistance({
          distance_type: ["low"],
          longitude: place.geometry.location.lng(),
          latitude: place.geometry.location.lat(),
        })
      );
    }
  };

  const handleLocationChange = (e) => {
    if (e.target.value === "") {
      dispatch(
        setLocationDistance({
          distance_type: [],
          longitude: null,
          latitude: null,
        })
      );
    }
    setAddress(e.target.value);
  };

  // Handle Input Change
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue === "") {
      dispatch(setFiltersSearch({ search_id: "" }));
      dispatch(fetchBusiness());
      setShowSuggestions2(false);
    }
    if (inputValue !== "" && !showSuggestions2) {
      setShowSuggestions2(true);
    }
    setValue(inputValue);
    clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(() => {
      dispatch(fetchSuggestion(value));
    }, 500);
    setTimeoutId(newTimeoutId);
  };

  // Handle Search Suggestion
  const handleSearchSuggestion = (id, name) => {
    setValue(name);
    const newFilter = { search_id: id };
    dispatch(setFiltersSearch(newFilter));
    dispatch(fetchBusiness());
    setShowSuggestions2(false);
    navigate("/businesses");
  };

  const onClearSearchValue = () => {
    setValue("");
    dispatch(setFiltersSearch({ search_id: "" }));
    dispatch(fetchBusiness());
    setShowSuggestions2(false);
  };

  // Function to hide the search suggestion when click outside

  useEffect(() => {
    // Function to close suggestions when clicked outside
    const handleClickOutside = (event) => {
      if (
        suggestionRef.current &&
        !suggestionRef.current.contains(event.target)
      ) {
        setShowSuggestions2(false);
      }
    };

    // Add event listener to document body
    document.body.addEventListener("click", handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* Mobile Search */}
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <>
              <Box
                sx={{
                  ml: 2,
                  [theme.breakpoints.down("sm")]: {
                    ml: 1,
                  },
                }}
              >
                <ButtonBase sx={{ borderRadius: "12px" }}>
                  <HeaderAvatarStyle
                    variant="rounded"
                    {...bindToggle(popupState)}
                  >
                    <IconSearch stroke={1.5} size="1.2rem" />
                  </HeaderAvatarStyle>
                </ButtonBase>
              </Box>
              <PopperStyle {...bindPopper(popupState)} transition>
                {() => (
                  <>
                    <Card
                      sx={{
                        background: "#fff",
                        height: "100vh",
                        [theme.breakpoints.down("sm")]: {
                          border: 0,
                          boxShadow: "none",
                        },
                      }}
                    >
                      <Box sx={{ p: 2 }}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item xs>
                            <Box
                              pb={3}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <Box>
                                <Link to={"/"}>
                                  <img
                                    src={logo}
                                    alt=""
                                    style={{
                                      width: "150px",
                                      height: "100%",
                                      objectFit: "cover",
                                      cursor: "pointer",
                                      [theme.breakpoints.down("sm")]: {
                                        width: "120px",
                                      },
                                    }}
                                  />
                                </Link>
                              </Box>
                              <ButtonBase
                                sx={{
                                  borderRadius: "12px",
                                  marginRight: "12px",
                                }}
                              >
                                <Avatar
                                  variant="rounded"
                                  sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    background: theme.palette.orange.light,
                                    color: theme.palette.orange.dark,
                                    "&:hover": {
                                      background: theme.palette.orange.dark,
                                      color: theme.palette.orange.light,
                                    },
                                  }}
                                  // {...bindToggle(popupState)}
                                  onClick={popupState.close}
                                >
                                  <IconX stroke={1.5} size="1.3rem" />
                                  {/* <TuneIcon sx={{ fontSize: "1.3rem" }} /> */}
                                </Avatar>
                              </ButtonBase>
                            </Box>
                            {/* Search Location */}
                            <Box
                              component="form"
                              sx={{
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 6,
                                paddingBottom: 6,
                                borderRadius: "10px",
                                boxShadow: "rgba(28, 28, 28, 0.08) 0px 2px 8px",
                                border: "1px solid rgb(232, 232, 232)",
                                p: "12px",
                                [theme.breakpoints.down("md")]: {
                                  height: "48px",
                                  background: "#fff",
                                },
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                                width: "100%",
                              }}
                            >
                              <Box
                                display={"flex"}
                                justifyContent={"flex-start"}
                                alignItems={"center"}
                                sx={{ position: "relative", width: "100%" }}
                              >
                                <LocationOnIcon
                                  stroke={1.5}
                                  size="1.1rem"
                                  fontWeight={700}
                                  style={{
                                    color: "#f35110",
                                  }}
                                />
                                {isLoaded && (
                                  <StandaloneSearchBox
                                    onLoad={(ref) => (inputRef.current = ref)}
                                    onPlacesChanged={handlePlaceChanged}
                                  >
                                    <InputBase
                                      sx={{
                                        ml: 1,
                                        width: "250px",
                                      }}
                                      type="text"
                                      value={address}
                                      onChange={(e) => {
                                        handleLocationChange(e);
                                      }}
                                      className="form-control"
                                      placeholder="Enter Location"
                                      inputProps={{
                                        "aria-label": "Search Address",
                                      }}
                                    />
                                  </StandaloneSearchBox>
                                )}
                              </Box>
                            </Box>
                            {/* Search Business */}
                            <Box
                              component="form"
                              mt={4}
                              sx={{
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 6,
                                paddingBottom: 6,
                                borderRadius: "10px",
                                boxShadow: "rgba(28, 28, 28, 0.08) 0px 2px 8px",
                                border: "1px solid rgb(232, 232, 232)",
                                p: "12px",
                                [theme.breakpoints.down("md")]: {
                                  height: "48px",
                                  background: "#fff",
                                },
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                                width: "100%",
                              }}
                            >
                              <Box
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{ position: "relative", width: "100%" }}
                              >
                                <IconSearch
                                  stroke={1.5}
                                  size="1.1rem"
                                  fontWeight={700}
                                  color={theme.palette.grey[500]}
                                />
                                {/* </IconButton> */}
                                <InputBase
                                  sx={{
                                    ml: 1,
                                    flex: 1,
                                    width: "100%",
                                    // width: "400px",
                                    [theme.breakpoints.down("lg")]: {
                                      // width: "300px",
                                    },
                                  }}
                                  value={valueM}
                                  onChange={handleInputChangeM}
                                  placeholder="Search Business"
                                  onFocus={() => {
                                    if (valueM !== "") {
                                      setShowSuggestions2M(true);
                                    }
                                  }}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Divider orientation="vertical" />
                                    </InputAdornment>
                                  }
                                />
                                {/* Second Search Suggestions */}
                                {showSuggestions2M && (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top: "100%",
                                      left: "0px",
                                      border: "1px solid rgb(207, 207, 207)",
                                      boxShadow:
                                        "rgba(28, 28, 28, 0.08) 0px 2px 8px",
                                      borderRadius: "10px",
                                      marginTop: "20px",
                                      width: "100%",
                                      // width: "440px",
                                      [theme.breakpoints.down("lg")]: {
                                        // width: "340px",
                                      },
                                      maxHeight: "500px",
                                      overflowY: "auto",
                                      zIndex: 9999,
                                    }}
                                    ref={suggestionRefM}
                                  >
                                    <Card>
                                      <Box py={1.5}>
                                        {searchSuggestion.length === 0 ? (
                                          <Box
                                            width="100%"
                                            sx={{
                                              padding: "10px 15px",
                                            }}
                                          >
                                            <Typography
                                              variant="h4"
                                              sx={{ marginBottom: "5px" }}
                                            >
                                              Oops!
                                            </Typography>
                                            <Typography variant="subtitl1">
                                              we could not find the result.
                                            </Typography>
                                          </Box>
                                        ) : (
                                          <>
                                            {searchSuggestion?.map(
                                              (suggestion, index) => {
                                                return (
                                                  <>
                                                    {suggestion ? (
                                                      <Box
                                                        width="100%"
                                                        display={"flex"}
                                                        justifyContent={
                                                          "flex-start"
                                                        }
                                                        alignItems={"center"}
                                                        sx={{
                                                          "&:hover": {
                                                            backgroundColor:
                                                              "rgb(246 246 246)",
                                                            cursor: "pointer",
                                                          },
                                                          padding: "10px",
                                                        }}
                                                        key={index}
                                                        onClick={() => {
                                                          popupState.close();
                                                          handleSearchSuggestionM(
                                                            suggestion?.id,
                                                            suggestion?.name
                                                          );
                                                        }}
                                                      >
                                                        <IconSearch
                                                          stroke={1.5}
                                                          size="1.1rem"
                                                          fontWeight={700}
                                                          color={
                                                            theme.palette
                                                              .grey[500]
                                                          }
                                                          style={{
                                                            marginRight: "10px",
                                                          }}
                                                        />
                                                        <Typography variant="subtitle1">
                                                          {suggestion?.name}
                                                        </Typography>
                                                      </Box>
                                                    ) : (
                                                      <Box
                                                        width="100%"
                                                        display={"flex"}
                                                        justifyContent={
                                                          "center"
                                                        }
                                                        alignItems={"center"}
                                                        mt={1}
                                                        sx={{
                                                          padding: "10px 15px",
                                                        }}
                                                      >
                                                        <Skeleton
                                                          variant="rectangular"
                                                          width="7%"
                                                          sx={{
                                                            marginRight: "10px",
                                                            borderRadius:
                                                              "10px",
                                                          }}
                                                          height={20}
                                                        />
                                                        <Skeleton
                                                          variant="rectangular"
                                                          width="93%"
                                                          height={20}
                                                          sx={{
                                                            borderRadius: "5px",
                                                          }}
                                                        />
                                                      </Box>
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                          </>
                                        )}
                                      </Box>
                                    </Card>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </>
                )}
              </PopperStyle>
            </>
          )}
        </PopupState>
      </Box>
      {/* Desktop Search */}
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Box
          component="form"
          sx={{
            marginLeft: "16px",
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 6,
            paddingBottom: 6,
            borderRadius: "10px",
            boxShadow: "rgba(28, 28, 28, 0.08) 0px 2px 8px",
            border: "1px solid rgb(232, 232, 232)",
            p: "12px",
            // [theme.breakpoints.down("lg")]: {},
            [theme.breakpoints.down("md")]: {
              height: "48px",
              marginLeft: 4,
              background: "#fff",
            },
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          {/* Search Location */}
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ position: "relative" }}
          >
            <LocationOnIcon
              stroke={1.5}
              size="1.1rem"
              fontWeight={700}
              style={{
                color: "#f35110",
              }}
            />

            {isLoaded && (
              <StandaloneSearchBox
                onLoad={(ref) => (inputRef.current = ref)}
                onPlacesChanged={handlePlaceChanged}
              >
                <InputBase
                  sx={{
                    ml: 1,
                    width: "180px",
                    [theme.breakpoints.down("lg")]: {
                      width: "130px",
                    },
                  }}
                  type="text"
                  value={address}
                  onChange={(e) => {
                    handleLocationChange(e);
                  }}
                  className="form-control"
                  placeholder="Enter Location"
                  inputProps={{ "aria-label": "Search Address" }}
                />
              </StandaloneSearchBox>
            )}
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              border: "1px solid #d1cdcd",
              marginRight: " 10px",
              marginLeft: " 10px",
              marginTop: "3px",
              marginBottom: "3px",
            }}
          />
          {/* Search Business */}
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ position: "relative" }}
          >
            <IconSearch
              stroke={1.5}
              size="1.1rem"
              fontWeight={700}
              color={theme.palette.grey[500]}
            />
            {/* </IconButton> */}
            <InputBase
              sx={{
                ml: 1,
                flex: 1,
                width: "400px",
                [theme.breakpoints.down("lg")]: {
                  width: "300px",
                },
              }}
              value={value}
              onChange={handleInputChange}
              placeholder="Search Business"
              onFocus={() => {
                if (value !== "") {
                  setShowSuggestions2(true);
                }
              }}
              endAdornment={
                value && (
                  <InputAdornment position="end">
                    <Divider orientation="vertical" />
                    <ButtonBase
                      sx={{ borderRadius: "0px" }}
                      onClick={() => {
                        onClearSearchValue();
                      }}
                    >
                      <IconX
                        stroke={1.7}
                        size="1.2rem"
                        color={theme.palette.grey[500]}
                      />
                    </ButtonBase>
                  </InputAdornment>
                )
              }
            />
            {/* Second Search Suggestions */}
            {showSuggestions2 && (
              <Box
                sx={{
                  position: "absolute",
                  top: "100%",
                  left: "0px",
                  border: "1px solid rgb(207, 207, 207)",
                  boxShadow: "rgba(28, 28, 28, 0.08) 0px 2px 8px",
                  borderRadius: "10px",
                  marginTop: "20px",
                  width: "440px",
                  [theme.breakpoints.down("lg")]: {
                    width: "340px",
                  },
                  maxHeight: "500px",
                  overflowY: "auto",
                }}
                ref={suggestionRef}
              >
                <Card>
                  <Box py={1.5}>
                    {searchSuggestion.length === 0 ? (
                      <Box
                        width="100%"
                        sx={{
                          padding: "10px 15px",
                        }}
                      >
                        <Typography variant="h4" sx={{ marginBottom: "5px" }}>
                          Oops!
                        </Typography>
                        <Typography variant="subtitl1">
                          we could not find the result.
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        {searchSuggestion?.map((suggestion, index) => {
                          return (
                            <>
                              {suggestion ? (
                                <Box
                                  width="100%"
                                  display={"flex"}
                                  justifyContent={"flex-start"}
                                  alignItems={"center"}
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "rgb(246 246 246)",
                                      cursor: "pointer",
                                    },
                                    padding: "10px",
                                  }}
                                  key={index}
                                  onClick={() => {
                                    handleSearchSuggestion(
                                      suggestion?.id,
                                      suggestion?.name
                                    );
                                  }}
                                >
                                  <IconSearch
                                    stroke={1.5}
                                    size="1.1rem"
                                    fontWeight={700}
                                    color={theme.palette.grey[500]}
                                    style={{ marginRight: "10px" }}
                                  />
                                  <Typography variant="subtitle1">
                                    {suggestion?.name}
                                  </Typography>
                                </Box>
                              ) : (
                                <Box
                                  width="100%"
                                  display={"flex"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  mt={1}
                                  sx={{
                                    padding: "10px 15px",
                                  }}
                                >
                                  <Skeleton
                                    variant="rectangular"
                                    width="7%"
                                    sx={{
                                      marginRight: "10px",
                                      borderRadius: "10px",
                                    }}
                                    height={20}
                                  />
                                  <Skeleton
                                    variant="rectangular"
                                    width="93%"
                                    height={20}
                                    sx={{ borderRadius: "5px" }}
                                  />
                                </Box>
                              )}
                            </>
                          );
                        })}
                      </>
                    )}
                  </Box>
                </Card>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SearchSection;
