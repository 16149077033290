import { Outlet } from "react-router-dom";

// ==============================|| BASIC LAYOUT ||============================== //

const BasicLayout = () => (
  <>
    <Outlet />
  </>
);

export default BasicLayout;
