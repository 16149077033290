/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { closeSnakebar } from "../../redux/ActByCustomer/CustomerSlice";
const SnackBar = () => {
  const dispatch = useDispatch();
  const { open, responseMessage } = useSelector((store) => store.customer);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      dispatch(closeSnakebar());
      return;
    }
    dispatch(closeSnakebar());
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={responseMessage}
        action={action}
      />
    </>
  );
};

export default SnackBar;
