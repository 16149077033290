import React, { useState } from "react";
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  // Rating,
  Typography,
  useTheme,
  Tooltip,
  Modal,
} from "@mui/material";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import XIcon from "@mui/icons-material/X";
// import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  actByCustomer,
  closeSnakebar,
  orderBookingThunks,
} from "../../redux/ActByCustomer/CustomerSlice";
import SnackBar from "../../Components/MessageAlertSnack/SnackBar";

const AboutService = ({ serviceDetails, id }) => {
  const [callModal, setCallModal] = useState(false);
  const [currentServiceImage, setCurrentServiceImage] = useState(
    serviceDetails?.images?.[0]
  );

  const { user, isAuthenticate } = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  const theme = useTheme();

  // Call Modal Handle
  const handleOpenCall = () => {
    if (isAuthenticate) {
      setCallModal(true);
    }
  };
  const handleCloseCall = () => {
    setCallModal(false);
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // swipeToSlide: false,
    // lazyLoad: true,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Open Call interface

  const openCallInterface = () => {
    if (isAuthenticate) {
      const formdata = new FormData();
      formdata.append("service_id", id);
      formdata.append("customer_id", user?.id);
      formdata.append("activity", "call");
      dispatch(actByCustomer(formdata));
      handleCloseCall();
      window.location.href = `tel:${serviceDetails?.business?.phone}`;
    }
  };

  const openWhatsApp = () => {
    if (isAuthenticate) {
      const formdata = new FormData();
      formdata.append("service_id", id);
      formdata.append("customer_id", user?.id);
      formdata.append("activity", "call");
      dispatch(actByCustomer(formdata));
      const whatsappURL = `https://api.whatsapp.com/send?phone=${serviceDetails?.business?.whatsapp_number}`;
      window.open(whatsappURL, "_blank");
    }
  };

  const openLocation = () => {
    if (isAuthenticate) {
      const formdata = new FormData();
      formdata.append("service_id", id);
      formdata.append("customer_id", user?.id);
      formdata.append("activity", "direction");
      dispatch(actByCustomer(formdata));
      const locationURL = `https://www.google.com/maps/place/${serviceDetails?.business?.latitude},${serviceDetails?.business?.longitude}`;
      window.open(locationURL, "_blank");
    }
  };

  // Handle Booking List
  // console.log("s",serviceDetails)
  const handleBooking = () => {
    if (isAuthenticate) {
      const formdata = new FormData();
      formdata.append("service_id", id);
      formdata.append("customer_id", user?.id);
      formdata.append("business_id", serviceDetails?.business_id);
      dispatch(orderBookingThunks(formdata));
      setTimeout(() => {
        dispatch(closeSnakebar());
      }, 6000);
    }
  };

  return (
    <>
      <SnackBar />
      <Box
        sx={{
          minHeight: "auto !important",
          backgroundColor: "#fff",
          width: "100%",
          padding: "50px 50px",
          paddingBottom: "20px !important",
          [theme.breakpoints.up("md")]: {
            padding: "50px 50px",
          },
          [theme.breakpoints.down("md")]: {
            padding: "36px",
          },
          [theme.breakpoints.down("sm")]: {
            padding: "26px",
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <Box
                sx={{
                  height: "430px",
                  [theme.breakpoints.down("md")]: {
                    height: "370px",
                  },
                }}
              >
                <CardMedia
                  src={currentServiceImage}
                  component="img"
                  title={"Service Image"}
                  sx={{
                    width: "100%",
                    height: "100%",
                    margin: 0,
                    objectFit: "cover",
                    objectPosition: "center",
                    borderRadius: "6px",
                    position: "relative",
                  }}
                />
              </Box>
            </Card>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              width={"100%"}
              height={"80px"}
              mt={3}
              mb={3}
            >
              {/* Image Slider */}
              <Card
                sx={{
                  width: "65%",
                  height: "100%",
                  [theme.breakpoints.down("md")]: {
                    width: "55%",
                  },
                  [theme.breakpoints.down("sm")]: {
                    width: "85%",
                  },
                }}
              >
                {/* <div style={{ width: "100%" }}> */}
                <Box sx={{ width: "100%", display: "flex" }}>
                  {/* <Slider {...settings} className="slide-small"> */}
                  {serviceDetails?.images?.map((img, i) => {
                    return (
                      <CardMedia
                        key={i}
                        src={img}
                        onClick={() => {
                          setCurrentServiceImage(img);
                        }}
                        component="img"
                        title={"Service Image"}
                        sx={{
                          height: "75px",
                          objectFit: "cover",
                          objectPosition: "center",
                          borderRadius: "6px",
                          position: "relative",
                          cursor: "pointer",
                          margin: "0px 10px",
                          [theme.breakpoints.down("sm")]: {
                            height: "65px",
                            margin: "0px 5px",
                          },
                        }}
                      />
                    );
                  })}
                  {/* </Slider> */}
                </Box>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <Box>
                <CardContent
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    padding: "16px 0px !important",
                    [theme.breakpoints.up("md")]: {
                      padding: "16px !important",
                    },
                  }}
                >
                  <Box pb={2}>
                    <Chip
                      label={
                        serviceDetails?.is_upcoming === "1"
                          ? "upcoming service"
                          : "service"
                      }
                      color="secondary"
                      size="medium"
                      variant="filled"
                      sx={{
                        borderRadius: "4px",
                        height: "30px",
                        color: "#673ab7",
                        // backgroundColor: "#c2abed",
                        backgroundColor: "#ede7f6",
                        transition:
                          "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        "&:hover": {
                          // backgroundColor: "#673ab7",
                          backgroundColor: "#6975f5",
                          color: "#f8f8f8",
                        },
                        "& .MuiChip-label": {
                          padding: "3px 6px !important",
                        },
                      }}
                    />
                  </Box>
                  <Box pb={2}>
                    <Typography variant="h2" textTransform="capitalize">
                      {serviceDetails?.services_name}
                    </Typography>
                  </Box>
                  <Box pb={2}>
                    <Typography variant="subtitle1">
                      {serviceDetails?.description}
                    </Typography>
                  </Box>
                  {/* <Box
                    pb={2}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      value={4.5}
                      precision={0.5}
                      readOnly
                      sx={{
                        // color: "#673ab7",
                        color: "rgb(104 117 245)",
                        fontSize: "1.43rem",
                      }}
                    ></Rating>
                    <Typography variant="body2" sx={{ padding: "0 10px" }}>
                      (12.8+)
                    </Typography>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ border: "1px solid #d1cdcd", marginRight: " 10px" }}
                    />
                    <FacebookIcon
                      sx={{
                        marginRight: " 10px",
                        cursor: "pointer",
                        color: "blue",
                      }}
                    />
                    <InstagramIcon
                      sx={{
                        marginRight: " 10px",
                        cursor: "pointer",
                      }}
                    />
                    <XIcon sx={{ cursor: "pointer" }} />
                  </Box> */}
                  <Box
                    pb={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {serviceDetails?.pricing_modes === "discount" ? (
                      <>
                        <Typography
                          color="primary"
                          variant="h2"
                          sx={{ marginRight: " 10px" }}
                        >
                          ₹ {serviceDetails?.price?.current_price}
                        </Typography>

                        <Typography
                          variant="body1"
                          textTransform="capitalize"
                          sx={{
                            textDecoration: "line-through",
                            marginRight: " 10px",
                          }}
                        >
                          {serviceDetails?.price?.actual_price}
                        </Typography>
                      </>
                    ) : (
                      ""
                    )}
                    {serviceDetails?.pricing_modes === "fixed" ? (
                      <>
                        <Typography
                          color="primary"
                          variant="h2"
                          sx={{ marginRight: " 10px" }}
                        >
                          ₹ {serviceDetails?.price}
                        </Typography>
                      </>
                    ) : (
                      ""
                    )}
                    {serviceDetails?.pricing_modes === "range" ? (
                      <>
                        <Typography
                          color="primary"
                          variant="h2"
                          // sx={{ marginRight: " 10px" }}
                        >
                          ₹ {serviceDetails?.price?.minimum_price}
                        </Typography>
                        &nbsp;&nbsp;{"-"}&nbsp;&nbsp;
                        <Typography
                          color="primary"
                          variant="h2"
                          sx={{ marginRight: " 10px" }}
                        >
                          ₹ {serviceDetails?.price?.maximum_price}
                        </Typography>
                      </>
                    ) : (
                      ""
                    )}
                    <Typography
                      variant="caption"
                      textTransform="capitalize"
                      sx={{}}
                    >
                      (Inclusive of all taxes)
                    </Typography>
                  </Box>
                  <Divider
                    flexItem
                    sx={{
                      borderWidth: "0px 0px thin",
                      borderColor: "rgb(227, 232, 239)",
                      margin: "25px 0",
                    }}
                  />

                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Typography
                        sx={{
                          textWrap: "nowrap",
                          marginRight: "15px",
                          [theme.breakpoints.down("sm")]: {
                            marginRight: "8px",
                          },
                        }}
                      >
                        Contact information
                      </Typography>
                      <Tooltip title="Whatsapp Number" placement="top" arrow>
                        <WhatsAppIcon
                          onClick={() => {
                            openWhatsApp();
                          }}
                          sx={{
                            borderRadius: "50%",
                            backgroundColor: "rgb(104 117 245)",
                            padding: "6px",
                            color: "#fff",
                            fontSize: "2.25rem",
                            cursor: "pointer",
                            marginRight: "15px",
                            [theme.breakpoints.down("sm")]: {
                              marginRight: "8px",
                            },
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Business Location" placement="top" arrow>
                        <LocationOnIcon
                          onClick={() => {
                            openLocation();
                          }}
                          sx={{
                            borderRadius: "50%",
                            backgroundColor: "rgb(104 117 245)",
                            padding: "6px",
                            color: "#fff",
                            fontSize: "2.25rem",
                            cursor: "pointer",
                            marginRight: "15px",
                            [theme.breakpoints.down("sm")]: {
                              marginRight: "8px",
                            },
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Phone Number" placement="top" arrow>
                        <PhoneIcon
                          onClick={() => {
                            handleOpenCall();
                          }}
                          sx={{
                            borderRadius: "50%",
                            backgroundColor: "rgb(104 117 245)",
                            padding: "6px",
                            color: "#fff",
                            fontSize: "2.25rem",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                      <Modal
                        open={callModal}
                        onClose={handleCloseCall}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            [theme.breakpoints.down("md")]: {
                              width: "40%",
                            },
                            [theme.breakpoints.down("sm")]: {
                              width: "80%",
                            },
                            bgcolor: "background.paper",
                            borderRadius: "6px",
                            border: "none !important",
                            boxShadow: 24,
                            p: 4,
                          }}
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          component="form"
                        >
                          <Typography
                            id="modal-modal-title"
                            variant="h2"
                            component="h2"
                          >
                            Call Confirmation
                          </Typography>
                          <Box
                            sx={{ mt: 2 }}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDirection={"column"}
                          >
                            <Typography variant="body1">
                              Are you sure you want to make a call?
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ marginTop: "8px" }}
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              Phone: &nbsp;
                              <Typography variant="subtitle1">
                                {serviceDetails?.business?.phone}
                              </Typography>
                            </Typography>
                          </Box>
                          <Box
                            width={"100%"}
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Button
                              onClick={openCallInterface}
                              sx={{ mt: 3 }}
                              color="primary"
                              variant="contained"
                            >
                              Call
                            </Button>
                            <Button
                              onClick={handleCloseCall}
                              sx={{ mt: 3 }}
                              color="error"
                              variant="contained"
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Box>
                      </Modal>
                    </Box>
                  </Box>

                  <Divider
                    flexItem
                    sx={{
                      borderWidth: "0px 0px thin",
                      borderColor: "rgb(227, 232, 239)",
                      margin: "25px 0",
                    }}
                  />
                  <Box
                    pb={2}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        marginRight: "20px",
                        padding: "10px 25px",
                        // backgroundColor: "#6774f3",
                        color: "#fff",
                        borderRadius: "6px",
                      }}
                      onClick={handleBooking}
                    >
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall css-1k33q06"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="ShoppingCartTwoToneIcon"
                        style={{
                          width: "1em",
                          height: "1em",
                          display: "inline-block",
                          fill: "currentcolor",
                          flexShrink: 0,
                          fontSize: "1.25rem",
                          userSelect: "none",
                          marginRight: "7px",
                        }}
                      >
                        <path
                          d="m15.55 11 2.76-5H6.16l2.37 5z"
                          opacity=".3"
                        ></path>
                        <path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path>
                      </svg>
                      Book Service
                    </Button>
                  </Box>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AboutService;
