/* eslint-disable react-hooks/exhaustive-deps */
// import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

// project imports
// import Breadcrumbs from "ui-component/extended/Breadcrumbs";
import Header from "./Header";
import Sidebar from "./Sidebar";
// import Customization from "../Customization";
// import navigation from "menu-items";

// assets
// import { IconChevronRight } from "@tabler/icons";
import { useCustomizationContext } from "../../context/Customization/CustomizationContext";
import Footer from "../../Components/Footer";

const drawerWidth = 260;
// borderRadius = 6;
// padding = 20;
// gridspacingwidth = 3;

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
    borderTopRightRadius: "6px",
    borderTopLeftRadius: "6px",
    // backgroundColor: "#f8f8f8",
    backgroundColor: "rgb(238, 242, 246)",
    marginTop: "88px !important",
    marginBottom: "20px",
    marginRight: "0px",
    transition: theme.transitions.create(
      "margin",
      open
        ? {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }
        : {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }
    ),
    [theme.breakpoints.up("md")]: {
      marginLeft: open ? 0 : -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
      marginRight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
      marginRight: "10px",
    },
  })
);

const FooterMain = styled("footer", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  ...theme.typography.mainContent,

  transition: theme.transitions.create(
    "margin",
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }
  ),
  [theme.breakpoints.up("md")]: {
    marginLeft: open ? 0 : -(drawerWidth - 20),
    width: `calc(100% - ${drawerWidth}px)`,
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "20px",
    width: `calc(100% - ${drawerWidth}px)`,
    padding: "16px",
    marginRight: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "10px",
    width: `calc(100% - ${drawerWidth}px)`,
    padding: "16px",
    marginRight: "10px",
  },
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const { opened, customizationDispatch } = useCustomizationContext();
  const handleLeftDrawerToggle = () => {
    customizationDispatch({ type: "@customization/SET_MENU", opened: !opened });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          [theme.breakpoints.up("md")]: {
            marginRight: "20px !important",
          },
        }}
      >
        <CssBaseline />
        {/* header */}
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.background.default,
            transition: opened ? theme.transitions.create("width") : "none",
          }}
        >
          <Toolbar>
            <Header />
          </Toolbar>
        </AppBar>

        {/* drawer */}
        <Sidebar
          drawerOpen={!matchDownMd ? opened : !opened}
          drawerToggle={handleLeftDrawerToggle}
        />

        {/* main content */}
        <Main theme={theme} open={opened}>
          <Outlet />
          {/* <FooterMain>
            <Footer />
          </FooterMain> */}
        </Main>
      </Box>
    </>
  );
};

export default MainLayout;
