import { isUnitless } from "@mui/material/styles/cssUtils";
import * as api from "../api/AuthApi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const signIn = createAsyncThunk(
  "auth/signin",
  async (userInfo, thunkAPI) => {
    try {
      const { error, data } = await api.signInUser(userInfo);
      if (error) {
        return thunkAPI.rejectWithValue(error);
      }
      return data?.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

const user = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const initialState = {
  success: false,
  error: null,
  user,
  isAuthenticate: false,
  isUrl: null,
  isLoginLoading: false,
  openLoginModal: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    clearSuccess: (state) => {
      state.success = false;
    },
    logout: () => {
      localStorage.removeItem("user");
      window.location.reload();
    },
    setIsAuthenticate: (state, action) => {
      state.isAuthenticate = action.payload.status;
    },
    setOpenLoginModal: (state, action) => {
      console.log("999", action.payload);
      state.openLoginModal = true;
      // isUrl = action.payload;
      state.isUrl = action.payload;
    },
    setCloseLoginModal: (state) => {
      state.openLoginModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.success = false;
        state.isLoginLoading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.success = true;
        // console.log(
        //   "function call ",
        //   action.payload,
        //   "after json .stringify payload are : ",
        //   JSON.stringify(action.payload)
        // );
        localStorage.setItem("user", JSON.stringify(action.payload));
        if (state.isUrl !== null) {
          window.location.href = state.isUrl;
        } else {
          window.location.reload();
        }
      })
      .addCase(signIn.rejected, (state, action) => {
        state.success = false;
        state.isLoginLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  clearErrors,
  clearSuccess,
  logout,
  setIsAuthenticate,
  setOpenLoginModal,
  setCloseLoginModal,
} = authSlice.actions;
export default authSlice.reducer;
