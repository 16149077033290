import * as api from "../api/CustomerApi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const actByCustomer = createAsyncThunk(
  "customer/actByCustomer",
  async (formData, thunkAPI) => {
    try {
      const { error, data } = await api.actByCustomerAPI(formData);
      if (error) {
        return thunkAPI.rejectWithValue(error);
      }
      if (data?.message) {
        return data;
      }
      return data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchBookingListById = createAsyncThunk(
  "customer/getBookingList",
  async (id, thunkAPI) => {
    try {
      const { error, data } = await api.getBookingList(id);
      if (error) {
        return thunkAPI.rejectWithValue(error);
      }
      return data?.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const addReviews = createAsyncThunk(
  "customer/addReviews",
  async (formData, thunkAPI) => {
    try {
      const { error, data } = await api.addReviews(formData);
      if (error) {
        return thunkAPI.rejectWithValue(error);
      }
      return data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchReviews = createAsyncThunk(
  "customer/fetchReviews",
  async (id, thunkAPI) => {
    try {
      const { error, data } = await api.getReviews(id);
      if (error) {
        return thunkAPI.rejectWithValue(error);
      }
      return data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);
// 
export const orderBookingThunks = createAsyncThunk(
  "customer/orderBookingThunks",
  async (formData, thunkAPI) => {
    try {
      const { error, data } = await api.orderBooking(formData);
      if (error) {
        return thunkAPI.rejectWithValue(error);
      }
        return data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);
const initialState = {
  bookingList: Array(12).fill(null),
  isBookingLoading: false,
  isSuccess: false,
  open: false,
  responseMessage: "",
  reviewList: Array(6).fill(null),
  isReviewSuccessLoading: false,
  openReviewModal: false,
  openReviewSuccessAlert: false,
  responseMessageAlert: "",
  rating: "",
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    openModal: (state) => {
      state.openReviewModal = true;
    },
    closeModal: (state) => {
      state.openReviewModal = false;
    },
    closeSnakebar: (state, payload) => {
      state.open = false;
      state.responseMessage = "";
    },
    closeReviewAlert: (state) => {
      state.openReviewSuccessAlert = false;
      state.responseMessageAlert = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Act by customer
      .addCase(actByCustomer.pending, (state) => {
        state.isSuccess = false;
      })
      .addCase(actByCustomer.fulfilled, (state, action) => {
        if (action.payload?.message) {
          state.open = true;
          state.responseMessage = action.payload?.message;
        }
      })
      .addCase(actByCustomer.rejected, (state, action) => {
        state.open = true;
        state.responseMessage = action.payload;
      })
      // order booking
      .addCase(orderBookingThunks.pending, (state) => {
        state.isSuccess = false;
      })
      .addCase(orderBookingThunks.fulfilled, (state, action) => {
       
          state.open = true;
          state.responseMessage = action.payload?.message;
        
      })
      .addCase(orderBookingThunks.rejected, (state, action) => {
        state.open = true;
        state.responseMessage = action.payload;
      })
      // Booking List
      .addCase(fetchBookingListById.pending, (state, action) => {
        state.isBookingLoading = true;
        state.bookingList = action.payload;
      })
      .addCase(fetchBookingListById.fulfilled, (state, action) => {
        state.isBookingLoading = false;
        state.bookingList = action.payload;
      })
      .addCase(fetchBookingListById.rejected, (state) => {
        state.isBookingLoading = false;
        state.bookingList = [];
      })
      // Add Reviews
      .addCase(addReviews.pending, (state) => {
        state.isReviewSuccessLoading = true;
      })
      .addCase(addReviews.fulfilled, (state) => {
        state.openReviewSuccessAlert = true;
        state.responseMessageAlert = "Success, Review added!";
        state.isReviewSuccessLoading = false;
        state.openReviewModal = false;
      })
      .addCase(addReviews.rejected, (state, action) => {
        state.openReviewSuccessAlert = true;
        state.responseMessageAlert = action.payload;
        state.isReviewSuccessLoading = false;
        state.openReviewModal = false;
      })
      // fetch Review
      .addCase(fetchReviews.fulfilled, (state, action) => {
        state.reviewList = action.payload?.data;
        state.rating = action.payload?.rating;
      })
      .addCase(fetchReviews.rejected, (state) => {
        state.reviewList = [];
      });
  },
});

export const { closeSnakebar, openModal, closeModal, closeReviewAlert } =
  customerSlice.actions;
export default customerSlice.reducer;
