import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));

// const clientId = process.env.REACR_APP_GOOGLE_CLIENT_ID;

root.render(
  <>
    <GoogleOAuthProvider clientId="874192663248-k394c54op61899c9efmkhgus0ooko5jd.apps.googleusercontent.com">
      <Router>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </GoogleOAuthProvider>
  </>
);
