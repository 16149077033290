import { API } from "./utils";

export const getBusiness = async (filters) => {
  try {
    const formdata = new FormData();
    if (
      filters[0]?.business_type[0] === "service_based" &&
      filters[0]?.business_type[1] === "product_based"
    ) {
      formdata.append("business_type", "both");
    } else {
      formdata.append("business_type", filters[0].business_type);
    }
    if (
      filters[1]?.distance_type[0] === "low" ||
      filters[1]?.distance_type[0] === "high"
    ) {
      formdata.append("distance", filters[1]?.distance_type);
      formdata.append("longitude", filters[1]?.longitude);
      formdata.append("latitude", filters[1]?.latitude);
    }

    formdata?.append("rating", filters[2]?.rating);

    if (filters[3]?.search_id) {
      formdata?.append("search_id", filters[3]?.search_id);
    }

    if (filters[4]?.genre_id) {
      formdata?.append("business_genre", filters[4]?.genre_id);
    }

    const { data } = await API.post("/getBusiness.php", formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (data?.status) {
      return { error: null, data };
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An unexpected error occurred.";
    const data = null;
    return { error: errorMessage, data };
  }
};

export const getBusinessById = async (id) => {
  try {
    const { data } = await API.get(`/getBusinessById.php?business_id=${id}`);
    if (data?.status) {
      return { error: null, data };
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An unexpected error occurred.";
    const data = null;
    return { error: errorMessage, data };
  }
};

export const getGenres = async () => {
  try {
    const { data } = await API.get("/businesses/getGenres.php");
    if (data?.status) {
      return { error: null, data };
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An unexpected error occurred.";
    const data = null;
    return { error: errorMessage, data };
  }
};

export const getSuggestion = async (searchQuery) => {
  try {
    const { data } = await API.get(
      `/customers/searchSuggestions.php?key=${searchQuery}`
    );

    return { error: null, data };
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An unexpected error occurred.";
    const data = null;
    return { error: errorMessage, data };
  }
};

export const getRelevantBusiness = async (details) => {
  try {
    const formdata = new FormData();
    formdata.append("business_genre", details?.business_genre);
    formdata.append("latitude", details?.latitude);
    formdata.append("longitude", details?.longitude);
    const { data } = await API.post("/get_relevant_businesses.php", formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return { error: null, data };
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An unexpected error occurred.";
    const data = null;
    return { error: errorMessage, data };
  }
};
