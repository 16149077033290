import { Navigate, useRoutes } from "react-router-dom";

// routes
import BusinessRoutes from "./BusinessRoutes";
import BasicRoutes from "./BasicRoutes";
import ViewBusinessRoutes from "./ViewBusinessRoute";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to="/businesses" replace />,
    },
    BusinessRoutes,
    BasicRoutes,
    ViewBusinessRoutes,
    {
      path: "*",
      element: <Navigate to="/businesses" replace />,
    },
  ]);
}
