import React, { useEffect } from "react";
/* eslint-disable react-hooks/exhaustive-deps */

import AboutService from "./AboutService";
import { useTheme } from "@emotion/react";

import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  circularProgressClasses,
  Card,
  CardContent,
  CardMedia,
  Skeleton,
  ButtonBase,
  Tooltip,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./Stylerelatedservice.css";

import { useParams } from "react-router-dom";
import {
  fetchRelatedService,
  fetchServiceDetailsById,
} from "../../redux/ServiceSlice/ServiceSlice";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import LoginModal from "../../Components/modal/LoginModal";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
const ViewService = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const { isAuthenticate } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const { serviceDetails, isLoadingServiceDetails, relevantService } =
    useSelector((store) => store.service);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // swipeToSlide: false,
    lazyLoad: true,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(fetchServiceDetailsById(id));
    dispatch(fetchRelatedService(id));
  }, []);

  const goToViewanotherService = (services_name, id) => {
    // navigate(`/businesses/viewbusiness/${business_name}/${id}`);
    // console.log("function call ");
    navigate(`/businesses/viewbusiness/service/${id}`);
    window.location.reload();
  };
  return (
    <>
      {isLoadingServiceDetails ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "85px",
            }}
          >
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
                animationDuration: "550ms",

                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: "round",
                },
              }}
              size={55}
              thickness={4}
            />
          </Box>
        </>
      ) : (
        <>
          {isAuthenticate ? (
            <>
              <AboutService serviceDetails={serviceDetails} id={id} />

              {/* Related Services */}
              <Box
                sx={{
                  minHeight: "auto !important",
                  backgroundColor: "#fff",
                  width: "100%",
                  padding: "50px 50px",
                  paddingTop: "20px !important",
                  [theme.breakpoints.up("md")]: {
                    padding: "50px 50px",
                  },
                  [theme.breakpoints.down("md")]: {
                    padding: "36px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    padding: "26px",
                  },
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                >
                  <Typography variant="h1">Related  Service</Typography>
                </Box>

                <Box pt={4}>
                  <Grid container>
                    {relevantService?.length === 0 ? (
                      <Box>
                        <Typography variant="subtitle1">
                          No related service found
                        </Typography>
                      </Box>
                    ) : (
                      <div style={{ width: "100%" }}>
                        <Slider {...settings} className="slide-related-service">
                          {relevantService?.map((item) => {
                            return (
                              <Grid item key={item?.id}>
                                <Card
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: "transparent",
                                    width: "100%",
                                    overflow: "visible",
                                    boxShadow:
                                      "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                    borderBottom: "2px solid #f8f8f8",
                                    // "&:hover": {
                                    //   transform: "scale(1.02)",
                                    // },
                                    transition:
                                      "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                  }}
                                >
                                  {item ? (
                                    <>
                                      {/* <Link
                                        onClick={() =>
                                          navigate(
                                            `/businesses/viewbusiness/service/${item?.services_name}/${item?.id}`
                                          )
                                        }
                                        style={{ textDecoration: "none" }}
                                      > */}
                                      <Box
                                        onClick={() => {
                                          goToViewanotherService(
                                            item?.services_name,
                                            item?.id
                                          );
                                        }}
                                        position="relative"
                                        width="100%"
                                        sx={{
                                          backgroundColor: "#6774f3",
                                          borderTopLeftRadius: "6px",
                                          borderTopRightRadius: "6px",
                                        }}
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                      >
                                        <>
                                          <CardMedia
                                            src={item?.image}
                                            component="img"
                                            title={"Business Image"}
                                            sx={{
                                              margin: "20px",
                                              width: "130px",
                                              height: "130px",
                                              objectFit: "cover",
                                              borderRadius: "50%",
                                              objectPosition: "center",
                                              border: "5px solid #fff",
                                              position: "relative",
                                            }}
                                          />
                                        </>
                                      </Box>
                                      <CardContent
                                        sx={{
                                          backgroundColor: "rgb(255, 255, 255)",
                                          padding: "16px",
                                          borderBottomLeftRadius: 6,
                                          borderBottomRightRadius: 6,
                                          height: "100%",
                                        }}
                                      >
                                        <>
                                          <Typography
                                            variant="subtitle1"
                                            textTransform="capitalize"
                                            pb={1.5}
                                            sx={{
                                              height: "30px",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {item?.services_name?.length > 35
                                              ? item?.services_name?.substring(
                                                  0,
                                                  35
                                                ) + "..."
                                              : item?.services_name}
                                          </Typography>
                                          <Box pb={1.5}>
                                            <Typography
                                              variant="body2"
                                              textTransform="capitalize"
                                              sx={{
                                                height: "40px",
                                                overflow: "hidden",
                                                // color: "#434c5c",
                                                fontSize: "0.875rem",
                                              }}
                                            >
                                              {item?.description?.length < 60
                                                ? item?.description
                                                : item?.description?.substring(
                                                    0,
                                                    60
                                                  ) + "..."}
                                            </Typography>
                                          </Box>
                                          <Box
                                            pb={1.5}
                                            display={"flex"}
                                            justifyContent={"space-between"}
                                            width={"100%"}
                                            alignItems={"center"}
                                          >
                                            <Box
                                              display={"flex"}
                                              justifyContent={"flex-start"}
                                              width={"100%"}
                                              alignItems={"center"}
                                            >
                                              {item?.pricing_modes ===
                                              "discount" ? (
                                                <>
                                                  <Typography variant="h4">
                                                    ₹{" "}
                                                    {
                                                      item?.prices
                                                        ?.current_price
                                                    }
                                                  </Typography>

                                                  <Typography
                                                    variant="body1"
                                                    sx={{
                                                      textDecoration:
                                                        "line-through",
                                                      marginLeft: "7px",
                                                    }}
                                                  >
                                                    {item?.prices?.actual_price}
                                                  </Typography>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {item?.pricing_modes ===
                                              "fixed" ? (
                                                <>
                                                  <Typography variant="h4">
                                                    ₹ {item?.prices}
                                                  </Typography>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {item?.pricing_modes ===
                                              "range" ? (
                                                <>
                                                  <Typography variant="h4">
                                                    ₹{" "}
                                                    {
                                                      item?.prices
                                                        ?.minimum_price
                                                    }
                                                  </Typography>
                                                  &nbsp;{"-"}&nbsp;
                                                  <Typography variant="h4">
                                                    ₹{" "}
                                                    {
                                                      item?.prices
                                                        ?.maximum_price
                                                    }
                                                  </Typography>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </Box>
                                            <Tooltip
                                              title="Book Service"
                                              placement="top"
                                              arrow
                                            >
                                              <ButtonBase
                                                sx={{
                                                  padding: "6px 16px",
                                                  backgroundColor: "#6774f3",
                                                  color: "#fff",
                                                  borderRadius: "6px",
                                                }}
                                              >
                                                <svg
                                                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall css-1k33q06"
                                                  focusable="false"
                                                  aria-hidden="true"
                                                  viewBox="0 0 24 24"
                                                  data-testid="ShoppingCartTwoToneIcon"
                                                  style={{
                                                    width: "1em",
                                                    height: "1em",
                                                    display: "inline-block",
                                                    fill: "currentcolor",
                                                    flexShrink: 0,
                                                    fontSize: "1.25rem",
                                                    userSelect: "none",
                                                  }}
                                                >
                                                  <path
                                                    d="m15.55 11 2.76-5H6.16l2.37 5z"
                                                    opacity=".3"
                                                  ></path>
                                                  <path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path>
                                                </svg>
                                              </ButtonBase>
                                            </Tooltip>
                                          </Box>
                                        </>
                                      </CardContent>
                                      {/* </Link> */}
                                    </>
                                  ) : (
                                    <>
                                      <Box
                                        position="relative"
                                        width="100%"
                                        sx={{
                                          backgroundColor: "#6774f3",
                                          borderTopLeftRadius: "6px",
                                          borderTopRightRadius: "6px",
                                        }}
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                      >
                                        <Skeleton
                                          variant="rectangular"
                                          width="130px"
                                          height="130px"
                                          sx={{
                                            borderRadius: "50%",
                                            margin: "20px",
                                          }}
                                        />
                                      </Box>
                                      <CardContent
                                        sx={{
                                          backgroundColor: "rgb(255, 255, 255)",
                                          padding: "16px",
                                          borderBottomLeftRadius: 6,
                                          borderBottomRightRadius: 6,
                                          height: "100%",
                                        }}
                                      >
                                        <Skeleton />
                                        <Skeleton
                                          variant="rectangular"
                                          sx={{ marginTop: "20px" }}
                                        />
                                        <Skeleton
                                          sx={{ marginTop: "20px" }}
                                          width={"100%"}
                                        />
                                      </CardContent>
                                    </>
                                  )}
                                </Card>
                              </Grid>
                            );
                          })}
                        </Slider>
                      </div>
                    )}
                  </Grid>
                </Box>
              </Box>
              <Footer />
            </>
          ) : (
            <>
              <LoginModal />
              <AboutService serviceDetails={serviceDetails} id={id} />
              {/* Related Services */}
              <Box
                sx={{
                  minHeight: "auto !important",
                  backgroundColor: "#fff",
                  width: "100%",
                  padding: "50px 50px",
                  paddingTop: "20px !important",
                  [theme.breakpoints.up("md")]: {
                    padding: "50px 50px",
                  },
                  [theme.breakpoints.down("md")]: {
                    padding: "36px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    padding: "26px",
                  },
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                >
                  <Typography variant="h1">Related Service</Typography>
                </Box>

                <Box pt={4}>
                  <Grid container>
                    {relevantService?.length === 0 ? (
                      <Box>
                        <Typography variant="subtitle1">
                          No related service found
                        </Typography>
                      </Box>
                    ) : (
                      <div style={{ width: "100%" }}>
                        <Slider {...settings} className="slide-related-service">
                          {relevantService?.map((item) => {
                            return (
                              <Grid item key={item?.id}>
                                <Card
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: "transparent",
                                    width: "100%",
                                    overflow: "visible",
                                    boxShadow:
                                      "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                    "&:hover": {
                                      transform: "scale(1.02)",
                                    },
                                    transition:
                                      "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                  }}
                                >
                                  {item ? (
                                    <>
                                      <Link
                                        to={`/businesses/viewbusiness/service/${item?.services_name}/${item?.id}`}
                                        target="_blank"
                                        style={{ textDecoration: "none" }}
                                      >
                                        <Box
                                          position="relative"
                                          width="100%"
                                          sx={{
                                            backgroundColor: "#6774f3",
                                            borderTopLeftRadius: "6px",
                                            borderTopRightRadius: "6px",
                                          }}
                                          display={"flex"}
                                          justifyContent={"center"}
                                          alignItems={"center"}
                                        >
                                          <>
                                            <CardMedia
                                              src={item?.image}
                                              component="img"
                                              title={"Business Image"}
                                              sx={{
                                                margin: "20px",
                                                width: "130px",
                                                height: "130px",
                                                objectFit: "cover",
                                                borderRadius: "50%",
                                                objectPosition: "center",
                                                border: "5px solid #fff",
                                                position: "relative",
                                              }}
                                            />
                                          </>
                                        </Box>
                                        <CardContent
                                          sx={{
                                            backgroundColor:
                                              "rgb(255, 255, 255)",
                                            padding: "16px",
                                            borderBottomLeftRadius: 6,
                                            borderBottomRightRadius: 6,
                                            height: "100%",
                                          }}
                                        >
                                          <>
                                            <Typography
                                              variant="subtitle1"
                                              textTransform="capitalize"
                                              pb={1.5}
                                              sx={{
                                                height: "30px",
                                                overflow: "hidden",
                                              }}
                                            >
                                              {item?.services_name?.length > 35
                                                ? item?.services_name?.substring(
                                                    0,
                                                    35
                                                  ) + "..."
                                                : item?.services_name}
                                            </Typography>
                                            <Box pb={1.5}>
                                              <Typography
                                                variant="body2"
                                                textTransform="capitalize"
                                                sx={{
                                                  minHeight: "40px",
                                                  overflow: "hidden",
                                                  // color: "#434c5c",
                                                  fontSize: "0.875rem",
                                                }}
                                              >
                                                {item?.description?.length < 65
                                                  ? item?.description
                                                  : item?.description?.substring(
                                                      0,
                                                      65
                                                    ) + "..."}
                                              </Typography>
                                            </Box>
                                            <Box
                                              pb={1.5}
                                              display={"flex"}
                                              justifyContent={"space-between"}
                                              width={"100%"}
                                              alignItems={"center"}
                                            >
                                              <Box
                                                display={"flex"}
                                                justifyContent={"flex-start"}
                                                width={"100%"}
                                                alignItems={"center"}
                                              >
                                                {item?.pricing_modes ===
                                                "discount" ? (
                                                  <>
                                                    <Typography variant="h4">
                                                      ₹{" "}
                                                      {
                                                        item?.prices
                                                          ?.current_price
                                                      }
                                                    </Typography>

                                                    <Typography
                                                      variant="body1"
                                                      sx={{
                                                        textDecoration:
                                                          "line-through",
                                                        marginLeft: "7px",
                                                      }}
                                                    >
                                                      {
                                                        item?.prices
                                                          ?.actual_price
                                                      }
                                                    </Typography>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                                {item?.pricing_modes ===
                                                "fixed" ? (
                                                  <>
                                                    <Typography variant="h4">
                                                      ₹ {item?.prices}
                                                    </Typography>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                                {item?.pricing_modes ===
                                                "range" ? (
                                                  <>
                                                    <Typography variant="h4">
                                                      ₹{" "}
                                                      {
                                                        item?.prices
                                                          ?.minimum_price
                                                      }
                                                    </Typography>
                                                    &nbsp;{"-"}&nbsp;
                                                    <Typography variant="h4">
                                                      ₹{" "}
                                                      {
                                                        item?.prices
                                                          ?.maximum_price
                                                      }
                                                    </Typography>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </Box>
                                              <Tooltip
                                                title="Book Service"
                                                placement="top"
                                                arrow
                                              >
                                                <ButtonBase
                                                  sx={{
                                                    padding: "6px 16px",
                                                    backgroundColor: "#6774f3",
                                                    color: "#fff",
                                                    borderRadius: "6px",
                                                  }}
                                                >
                                                  <svg
                                                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall css-1k33q06"
                                                    focusable="false"
                                                    aria-hidden="true"
                                                    viewBox="0 0 24 24"
                                                    data-testid="ShoppingCartTwoToneIcon"
                                                    style={{
                                                      width: "1em",
                                                      height: "1em",
                                                      display: "inline-block",
                                                      fill: "currentcolor",
                                                      flexShrink: 0,
                                                      fontSize: "1.25rem",
                                                      userSelect: "none",
                                                    }}
                                                  >
                                                    <path
                                                      d="m15.55 11 2.76-5H6.16l2.37 5z"
                                                      opacity=".3"
                                                    ></path>
                                                    <path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path>
                                                  </svg>
                                                </ButtonBase>
                                              </Tooltip>
                                            </Box>
                                          </>
                                        </CardContent>
                                      </Link>
                                    </>
                                  ) : (
                                    <>
                                      <Box
                                        position="relative"
                                        width="100%"
                                        sx={{
                                          backgroundColor: "#6774f3",
                                          borderTopLeftRadius: "6px",
                                          borderTopRightRadius: "6px",
                                        }}
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                      >
                                        <Skeleton
                                          variant="rectangular"
                                          width="130px"
                                          height="130px"
                                          sx={{
                                            borderRadius: "50%",
                                            margin: "20px",
                                          }}
                                        />
                                      </Box>
                                      <CardContent
                                        sx={{
                                          backgroundColor: "rgb(255, 255, 255)",
                                          padding: "16px",
                                          borderBottomLeftRadius: 6,
                                          borderBottomRightRadius: 6,
                                          height: "100%",
                                        }}
                                      >
                                        <Skeleton />
                                        <Skeleton
                                          variant="rectangular"
                                          sx={{ marginTop: "20px" }}
                                        />
                                        <Skeleton
                                          sx={{ marginTop: "20px" }}
                                          width={"100%"}
                                        />
                                      </CardContent>
                                    </>
                                  )}
                                </Card>
                              </Grid>
                            );
                          })}
                        </Slider>
                      </div>
                    )}
                  </Grid>
                </Box>
              </Box>
              <Footer />
            </>
          )}
        </>
      )}
    </>
  );
};

export default ViewService;
