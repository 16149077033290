import { API } from "./utils";

export const getServiceById = async (id) => {
  try {
    const { data } = await API.get(
      `/admin/getServiceById.php?service_id=${id}`
    );
    if (data?.status) {
      return { error: null, data };
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An unexpected error occurred.";
    const data = null;
    return { error: errorMessage, data };
  }
};

export const getReletedService = async (id) => {
  try {
    const { data } = await API.get(
      `/customers/get_relevant_services.php?service_id=${id}`
    );
    return { error: null, data };
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An unexpected error occurred.";
    const data = null;
    return { error: errorMessage, data };
  }
};
