import reducer from "./CustomizationReducer";

/* eslint-disable react/prop-types */
const { createContext, useReducer, useContext } = require("react");

const CustomizationContext = createContext(null);

const INITIALSTATE = {
  isOpen: [], // for active default menu
  defaultId: "default",
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  opened: true,
};

export const CustomizationContextProvider = ({ children }) => {
  const [state, customizationDispatch] = useReducer(reducer, INITIALSTATE);
  return (
    <CustomizationContext.Provider value={{ ...state, customizationDispatch }}>
      {children}
    </CustomizationContext.Provider>
  );
};

export const useCustomizationContext = () => {
  return useContext(CustomizationContext);
};
