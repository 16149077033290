import { API } from "./utils";

export const actByCustomerAPI = async (formData) => {
  try {
    const { data } = await API.post(
      `/customers/act_by_customer.php`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (data?.status) {
      return { error: null, data };
    }
    if (data?.status === false) {
      return { error: data?.message, data: null };
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An unexpected error occurred.";
    const data = null;
    return { error: errorMessage, data };
  }
};

export const getBookingList = async (id) => {
  try {
    console.log("function call :  --")
    const { data } = await API.get(
      `/customers/get_orders.php?customer_id=${id}`
      // `/customers/get_orders.php?customer_id=12`
      // `/customers/getBooking.php?customer_id=${id}`
    );
    if (data?.status) {
      console.log("data  : ",data)
      return { error: null, data };
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An unexpected error occurred.";
    const data = null;
    return { error: errorMessage, data };
  }
};

export const addReviews = async (formData) => {
  try {
    const { data } = await API.post(`/customers/writeReview.php`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (data?.status) {
      return { error: null, data };
    }
    if (data?.status === false) {
      return { error: data?.message, data: null };
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An unexpected error occurred.";
    const data = null;
    return { error: errorMessage, data };
  }
};

export const getReviews = async (id) => {
  try {
    const { data } = await API.get(
      `https://www.dekhomarket.com/apis/customers/fetchReviews.php?business_id=${id}`
    );
    if (data?.status) {
      return { error: null, data };
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An unexpected error occurred.";
    const data = null;
    return { error: errorMessage, data };
  }
};

// Order booking APi for post order


export const orderBooking = async (formData) => {
  try {
    const { data } = await API.post(
      `/customers/order_booking.php`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (data?.status) {
      return { error: null, data };
    }
    if (data?.status === false) {
      return { error: data?.message, data: null };
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An unexpected error occurred.";
    const data = null;
    return { error: errorMessage, data };
  }
};