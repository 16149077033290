import { Link } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { useCustomizationContext } from "../../../context/Customization/CustomizationContext";

// material-ui
import { Box } from "@mui/material";

// project imports
// import config from "config";
// import Logo from "./Logo";
// import { MENU_OPEN } from "store/actions";
// import logo from "../../../Assets/dekho_market_logo.png";
import logo from "../../../Assets/DekhoMarket-01.png";
import { useTheme } from "@emotion/react";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  // const defaultId = useSelector((state) => state.customization.defaultId);
  // const dispatch = useDispatch();
  // const { defaultId, customizationDispatch } = useCustomizationContext();
  const theme = useTheme();
  return (
    <Box>
      {/* <ButtonBase
      disableRipple
      // onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })}
      onClick={() =>
        customizationDispatch({
          type: "@customization/SET_MENU",
          id: defaultId,
        })
      }
      component={Link}
      to={"/"}
      sx={{ width: "130px", height: "50px" }}
    > */}
      {/* <Logo /> */}
      <Link to={"/"}>
        <img
          src={logo}
          alt=""
          style={{
            width: "150px",
            height: "100%",
            objectFit: "cover",
            cursor: "pointer",
            [theme.breakpoints.down("sm")]: {
              width: "120px",
            },
          }}
        />
      </Link>
      {/* // </ButtonBase> */}
    </Box>
  );
};

export default LogoSection;
