import { lazy } from "react";

// project imports
import ViewBusinessLayout from "../layouts/MainLayout/ViewBusinessLayout.js";
import Loadable from "../Components/Loader/Loadable.js";
import ViewService from "../Pages/ViewServices/index.js";
import BookingList from "../Pages/BookingList/index.js";
import { ProtectedRoute } from "./ProtectedRoute.js";

const ViewBusinessDetails = Loadable(
  lazy(() => import("../Pages/ViewBusinessDetails/index.js"))
);

// =============================|| VIEWBUSINESS ROUTING ||============================== //

const ViewBusinessRoutes = {
  path: "/businesses/viewbusiness/",
  element: <ViewBusinessLayout />,
  children: [
    {
      path: ":id",
      // path: ":businessname/:id",
      element: <ProtectedRoute element={<ViewBusinessDetails />} />,
    },
    {
      // path: "service/:servicename/:id",
      path: "service/:id",
      element: <ProtectedRoute element={<ViewService />} />,
    },
    {
      path: "manageservice",
      element: <ProtectedRoute element={<BookingList />} />,
      // element: <BookingList />,
    },
  ],
};

export default ViewBusinessRoutes;
