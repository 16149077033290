/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  circularProgressClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "../../Components/modal/LoginModal";
import { fetchBookingListById } from "../../redux/ActByCustomer/CustomerSlice";
import Footer from "../../Components/Footer";

const BookingList = () => {
  const { isAuthenticate, user } = useSelector((store) => store.auth);
  const theme = useTheme();
  const { bookingList, isBookingLoading } = useSelector(
    (store) => store.customer
  );
  const [totalPrice, setTotalPrice] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("37 line")
    dispatch(fetchBookingListById(user?.id));
    if (isAuthenticate) {
      dispatch(fetchBookingListById(user?.id));
    }
  }, []);

  const calculateServiceTotal = () => {
    let serviceTotal = 0;
    bookingList?.forEach((service) => {
      if (service?.pricing_modes === "fixed") {
        serviceTotal += Number(service?.price1);
      }
      if (service?.pricing_modes === "discount") {
        serviceTotal += Number(service?.price?.current_price);
      }
      if (service?.pricing_modes === "range") {
        serviceTotal += Number(service?.price?.minimum_price);
      }
    });
    setTotalPrice(serviceTotal);
    console.log(bookingList);
    console.log(serviceTotal);
  };
  useEffect(() => {
    if (bookingList) {
      calculateServiceTotal();
    }
  }, [bookingList]);

  return (
    <>
      {isBookingLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "85px",
            // marginBottom: "25px",
          }}
        >
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: (theme) =>
                theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
              animationDuration: "550ms",

              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: "round",
              },
            }}
            size={55}
            thickness={4}
          />
        </Box>
      ) : (
        <>
         
            <>
              <Box
                sx={{
                  background:
                    "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(161,205,247,1) 0%, rgba(152,185,255,1) 64%)",
                  padding: "35px 169px",
                  [theme.breakpoints.up("md")]: {
                    padding: "35px 169px",
                  },
                  [theme.breakpoints.down("md")]: {
                    padding: "36px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    padding: "26px",
                  },
                }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Typography
                    variant="h1"
                    color="primary"
                    sx={{
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "1.6rem",
                      },
                    }}
                  >
                    Business
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#535965",
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "1.6rem",
                      },
                    }}
                  >
                    &nbsp; / Service List
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  minHeight: "auto !important",
                  backgroundColor: "#fff",
                  padding: "50px 169px",
                  width: "100%",
                  [theme.breakpoints.up("md")]: {
                    padding: "50px 169px",
                  },
                  [theme.breakpoints.down("md")]: {
                    padding: "36px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    padding: "26px",
                  },
                }}
              >
                <Grid container>
                  <Grid item xs="12">
                    {bookingList?.length === 0 ? (
                      <>
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          width={"100%"}
                        >
                          <CardMedia
                            src={
                              "https://berrydashboard.io/assets/empty-DURDY3lt.svg"
                            }
                            component="img"
                            title={"Business Image"}
                            sx={{
                              width: "50%",
                              height: "100%",
                              [theme.breakpoints.down("sm")]: {
                                width: "90%",
                                height: "90%",
                              },
                              margin: 3,
                              objectFit: "cover",
                              objectPosition: "center",
                              position: "relative",
                              //   zIndex: 1,
                            }}
                          />
                        </Box>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Typography variant="h2" mb={2}>
                            Your Booking List is empty
                          </Typography>
                          <Link to={"/businesses"}>
                            <Button variant="contained">Fill It</Button>
                          </Link>
                        </Box>
                      </>
                    ) : (
                      <>
                        {/* Booking List */}
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography variant="subtitle1">
                                    Service Name
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography variant="subtitle1">
                                    Price
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  Business Name
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {bookingList?.map((row) => (
                                <TableRow
                                  key={row?.id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <Box
                                      display={"flex"}
                                      justifyContent={"flex-start"}
                                      alignItems={"center"}
                                    >
                                      <CardMedia
                                        src={row?.service?.image}
                                        component="img"
                                        title={"Business Image"}
                                        sx={{
                                          height: "80px",
                                          width: "100px",
                                          margin: 0,
                                          objectFit: "cover",
                                          objectPosition: "center",
                                          borderRadius: "6px",
                                          position: "relative",
                                          cursor: "pointer",
                                        }}
                                      />
                                      <Box ml={2}>
                                        <Typography variant="subtitle1">
                                          {row?.service?.service_name?.length > 35
                                            ? row?.service?.service_name?.substring(
                                                0,
                                                35
                                              ) + "..."
                                            : row?.service?.service_name}
                                        </Typography>
                                        <Typography variant="caption">
                                          {row?.service?.description?.length < 65
                                            ? row?.service?.description
                                            : row?.service?.description?.substring(
                                                0,
                                                65
                                              ) + "..."}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="right">
                                    <Box
                                      display={"flex"}
                                      flexDirection={"row"}
                                      justifyContent={"center"}
                                      alignItems={"center"}
                                    >
                                      {/* <Typography variant="subtitle1">
                                    {row?.price}
                                  </Typography> */}
                                      {row?.service?.pricing_mode === "discount" ? (
                                        <>
                                          <Typography
                                            variant="subtitle1"
                                            sx={{
                                              marginRight: "10px",
                                              textWrap: "nowrap",
                                            }}
                                          >
                                            ₹ {row?.service?.price?.current_price}
                                          </Typography>

                                          <Typography
                                            variant="caption"
                                            sx={{
                                              textDecoration: "line-through",
                                              textWrap: "nowrap",
                                            }}
                                          >
                                            {row?.service?.price?.actual_price}
                                          </Typography>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {row?.service?.pricing_mode === "fixed" ? (
                                        <>
                                          <Typography
                                            variant="subtitle1"
                                            sx={{ textWrap: "nowrap" }}
                                          >
                                            ₹ {row?.service?.price}
                                          </Typography>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {row?.service?.pricing_mode === "range" ? (
                                        <>
                                          <Typography
                                            variant="subtitle1"
                                            sx={{ textWrap: "nowrap" }}
                                          >
                                           ₹ {row?.service?.price?.minimum_price}
                                          </Typography>
                                          &nbsp;{"-"}&nbsp;
                                          <Typography
                                            variant="subtitle1"
                                            sx={{ textWrap: "nowrap" }}
                                          >
                                            ₹ {row?.service?.price?.maximum_price}
                                          </Typography>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {/* <Typography
                                    variant="caption"
                                    sx={{
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    {Number(row?.price) + 200}
                                  </Typography> */}
                                    </Box>
                                  </TableCell>
                                  
                                  <TableCell align="center">
                                  <Box
                                      display={"flex"}
                                      justifyContent={"flex-start"}
                                      alignItems={"center"}
                                    >
                                  <CardMedia
                                        src={row?.business?.image}
                                        component="img"
                                        title={"Business Image"}
                                        sx={{
                                          height: "80px",
                                          width: "100px",
                                          margin: 0,
                                          objectFit: "cover",
                                          objectPosition: "center",
                                          borderRadius: "0px",
                                          position: "relative",
                                          cursor: "pointer",
                                        }}
                                      />
                                    {/* <Link
                                      to={`/businesses/viewbusiness/${row?.business?.business_name}/${row?.business?.id}`}
                                      style={{
                                        textDecoration: "none",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        sx={{
                                          ":hover": {
                                            textDecoration: "underline",
                                          },
                                        }}
                                      >
                                      
                                      </Typography>
                                     */}
                                    <Box ml={2}>
                                        <Typography variant="subtitle1">
                                        {row?.business?.business_name?.length >
                                        35
                                          ? row?.business?.business_name?.substring(
                                              0,
                                              35
                                            ) + "..."
                                          : row?.business?.business_name}
                                        </Typography>
                                        <Typography variant="caption">
                                          {row?.business?.description?.length < 65
                                            ? row?.business?.description
                                            : row?.business?.description?.substring(
                                                0,
                                                65
                                              ) + "..."}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Grid
                          container
                          mt={3}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"flex-start"}
                        >
                          <Grid item xs="12" sm="5" md="6" lg="8">
                            <Link to={"/businesses"}>
                              <Button sx={{ marginBottom: "10px" }}>
                                <KeyboardBackspaceIcon
                                  sx={{ marginRight: "10px" }}
                                />
                                Continue Booking
                              </Button>
                            </Link>
                          </Grid>
                          {/* <Grid item xs="12" sm="7" md="6" lg="4">
                            <Box
                              py={2}
                              sx={{
                                border: "1px solid rgb(227, 232, 239)",
                                borderRadius: "8px",
                                padding: "40px",
                              }}
                            >
                              <Typography variant="subtitle1">
                                Service Summary
                              </Typography>
                              <Divider
                                flexItem
                                sx={{
                                  borderWidth: "0px 0px thin",
                                  borderColor: "rgb(227, 232, 239)",
                                  margin: "10px 0",
                                }}
                              />
                              <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Typography variant="body1">
                                  Sub Total :
                                </Typography>
                                <Typography variant="body1">
                                  ₹ {totalPrice}
                                </Typography>
                              </Box>
                              <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                sx={{ margin: "10px 0" }}
                              >
                                <Typography variant="body1">
                                  Coupon Discount :
                                </Typography>
                                <Typography variant="body1">₹ 0</Typography>
                              </Box>
                              <Divider
                                flexItem
                                sx={{
                                  borderWidth: "0px 0px thin",
                                  borderColor: "rgb(227, 232, 239)",
                                  margin: "10px 0",
                                }}
                              />
                              <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Typography variant="h4">Total :</Typography>
                                <Typography variant="h4">
                                  ₹ {totalPrice}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid> */}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Footer />
            </>
         
        </>
      )}
    </>
  );
};

export default BookingList;
