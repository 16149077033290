import { lazy } from "react";

// project imports
import BasicLayout from "../layouts/BasicLayout/index.js";
import Loadable from "../Components/Loader/Loadable.js";

const Home = Loadable(lazy(() => import("../Pages/Home/index.js")));

// ==============================|| Basic ROUTING ||============================== //

const BasicRoutes = {
  path: "/",
  element: <BasicLayout />,
  children: [
    {
      path: "",
      element: <Home />,
    },
  ],
};

export default BasicRoutes;
