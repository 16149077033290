import { lazy } from "react";

// project imports
import MainLayout from "../layouts/MainLayout/index.js";
import Loadable from "../Components/Loader/Loadable.js";

const Businesses = Loadable(
  lazy(() => import("../Pages/Businesses/Businesses.js"))
);

// ==============================|| BUSINESS ROUTING ||============================== //

const BusinessRoutes = {
  path: "/businesses",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <Businesses />,
    },
  ],
};

export default BusinessRoutes;
