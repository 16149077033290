/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Card,
  Chip,
  Drawer,
  Rating,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// third-party
// import PerfectScrollbar from "react-perfect-scrollbar";
// import { BrowserView, MobileView } from "react-device-detect";

// project imports
// import LogoSection from "../LogoSection";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchBusiness,
  setFilters,
} from "../../../redux/Business/BusinessSlice";

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  // const { type } = useParams();
  const dispatch = useDispatch();
  const [startingFilterSet, setStartingFilterSet] = useState(false);

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  // Business Chips State
  const [businessChips, setBusinessChips] = useState({
    service_based: false,
    product_based: false,
  });

  // Distance Chips State
  const [distanceChips, setDistanceChips] = useState({
    low: false,
    high: false,
  });

  // Rating Chips State
  const [rating, setRating] = useState(null);

  // Setting the Filter when page open
  const setStartingFilter = () => {
    setBusinessChips({
      ...businessChips,
      service_based: true,
      product_based: true,
    });
    setStartingFilterSet(true);
  };

  useEffect(() => {
    setStartingFilter();
  }, []);

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  // Function to update filter array when something add or remove in business, duration and rating

  const updateFilter = () => {
    const newFilter = [
      { business_type: [] },
      { distance_type: [], longitude, latitude },
      { rating: 0 },
    ];
    // Add business state to filter
    if (businessChips.service_based) {
      newFilter[0].business_type.push("service_based");
    }
    if (businessChips.product_based) {
      newFilter[0].business_type.push("product_based");
    }

    // Add duration state to filter
    if (distanceChips.low) {
      newFilter[1].distance_type.push("low");
      newFilter[1].latitude = latitude;
      newFilter[1].longitude = longitude;
    }
    if (distanceChips.high) {
      newFilter[1].distance_type.push("high");
      newFilter[1].latitude = latitude;
      newFilter[1].longitude = longitude;
    }

    // Add rating state to filter
    if (rating !== null) {
      newFilter[2].rating = rating;
    }

    dispatch(setFilters(newFilter));
    dispatch(fetchBusiness());
  };

  useEffect(() => {
    if (startingFilterSet) {
      updateFilter();
    }
  }, [businessChips, distanceChips, rating, startingFilterSet]);

  // Business chips handle
  const handleBusinessChips = (businesstype) => {
    setBusinessChips((prevState) => ({
      ...prevState,
      [businesstype]: !prevState[businesstype],
    }));
  };
  const handleDeleteBusinessChips = (businesstype) => {
    setBusinessChips((prevState) => ({
      ...prevState,
      [businesstype]: !prevState[businesstype],
    }));
  };

  // Distance chips handle
  const handleDistanceChips = (distancetype) => {
    if (!latitude || !longitude) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Update latitude and longitude from geolocation
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);

            // Set the filter state based on the distancetype
            setDistanceChips((prevState) => ({
              low: distancetype === "low" ? !prevState.low : false,
              high: distancetype === "high" ? !prevState.high : false,
            }));
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              console.log("User denied Geolocation");
              // Inform the user that geolocation access is denied
              // You can display a message or handle it as needed
              alert(
                "Geolocation denied, distance filter cannot be applied because geolocation access is denied"
              );
            }
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
        // Inform the user that geolocation is not supported
        // You can display a message or handle it as needed
      }
    } else {
      // Set the filter state based on the distancetype
      setDistanceChips((prevState) => ({
        low: distancetype === "low" ? !prevState.low : false,
        high: distancetype === "high" ? !prevState.high : false,
      }));
    }
  };
  const handleDeleteDistanceChips = (distancetype) => {
    setDistanceChips((prevState) => ({
      ...prevState,
      [distancetype]: !prevState[distancetype],
    }));
  };

  // Rating chips handle
  const handleRatingChips = (ratingvalue) => {
    setRating(ratingvalue);
  };
  const handleDeleteRatingChips = () => {
    setRating(null);
  };

  // Reset All Handle
  const handleResetAll = () => {
    setStartingFilter();
  };

  const drawerWidth = 260;

  const drawer = (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
          {/* <LogoSection /> */}
        </Box>
      </Box>
      {/* <BrowserView> */}
      <Box p={2} pt={0}>
        {/* Business Chips*/}
        {businessChips.service_based === true ||
        businessChips.product_based === true ? (
          <Card
            sx={{
              margin: " 0 16px 16px 16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              borderRadius: "6px",
              // backgroundColor: "#f9f9f9",
              backgroundColor: "rgb(238, 242, 246)",
              padding: "12px",
              flex: "wrap",
            }}
          >
            <Typography variant="subtitle1" sx={{ marginBottom: "6px" }}>
              Business
            </Typography>
            {businessChips.service_based === true ? (
              <Chip
                label="Service Based"
                onDelete={() => {
                  handleDeleteBusinessChips("service_based");
                }}
                color="primary"
                sx={{ marginBottom: "6px" }}
              />
            ) : (
              ""
            )}
            {businessChips.product_based === true ? (
              <Chip
                label="Product Based"
                onDelete={() => {
                  handleDeleteBusinessChips("product_based");
                }}
                color="secondary"
                sx={{ marginBottom: "6px" }}
              />
            ) : (
              ""
            )}
          </Card>
        ) : (
          ""
        )}

        {/* Distance Chips */}
        {distanceChips.low === true || distanceChips.high === true ? (
          <Card
            sx={{
              margin: " 0 16px 16px 16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              borderRadius: "6px",
              // backgroundColor: "#f9f9f9",
              backgroundColor: "rgb(238, 242, 246)",
              padding: "12px",
              flex: "wrap",
            }}
          >
            <Typography variant="subtitle1" sx={{ marginBottom: "6px" }}>
              Distance
            </Typography>
            {distanceChips.low === true ? (
              <Chip
                label="Low To High"
                onDelete={() => {
                  handleDeleteDistanceChips("low");
                }}
                color="secondary"
                sx={{ marginBottom: "6px" }}
              />
            ) : (
              ""
            )}
            {distanceChips.high === true ? (
              <Chip
                label="High To Low"
                onDelete={() => {
                  handleDeleteDistanceChips("high");
                }}
                color="primary"
                sx={{ marginBottom: "6px" }}
              />
            ) : (
              ""
            )}
          </Card>
        ) : (
          ""
        )}

        {/* Rating Chips */}
        {rating !== null ? (
          <Card
            sx={{
              margin: " 0 16px 16px 16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              borderRadius: "6px",
              // backgroundColor: "#f9f9f9",
              backgroundColor: "rgb(238, 242, 246)",
              padding: "12px",
              flex: "wrap",
            }}
          >
            <Typography variant="subtitle1" sx={{ marginBottom: "6px" }}>
              Rating
            </Typography>

            <Chip
              label={"Rating: " + rating + "/5"}
              onDelete={() => {
                handleDeleteRatingChips();
              }}
              color="warning"
              sx={{ marginBottom: "6px" }}
            />
          </Card>
        ) : (
          ""
        )}

        {/* Business Type Filter */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              minHeight: "42px !important",
              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: "0px",
              },
            }}
          >
            <Typography variant="subtitle1">Business</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={businessChips.service_based}
                    onChange={() => handleBusinessChips("service_based")}
                  />
                }
                label="Service Based"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={businessChips.product_based}
                    onChange={() => handleBusinessChips("product_based")}
                    color="secondary"
                  />
                }
                label="Product Based"
              />
            </FormGroup>
          </AccordionDetails>
        </Accordion>

        {/* Business Duration Filter */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              minHeight: "42px !important",
              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: "0px",
              },
            }}
          >
            <Typography variant="subtitle1">Distance</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup sx={{ display: "flex" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={distanceChips.high}
                    onChange={() => {
                      handleDistanceChips("high");
                    }}
                  />
                }
                label="High To Low"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={distanceChips.low}
                    onChange={() => {
                      handleDistanceChips("low");
                    }}
                  />
                }
                label="Low To High"
              />
            </FormGroup>
          </AccordionDetails>
        </Accordion>

        {/* Business Rating Filter */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              minHeight: "42px !important",
              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: "0px",
              },
            }}
          >
            <Typography variant="subtitle1">Rating</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={1}>
              <Rating
                name="half-rating"
                value={rating}
                onChange={(event, newValue) => {
                  handleRatingChips(newValue);
                }}
                precision={0.5}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Box>
          <Button
            color="error"
            fullWidth
            variant="contained"
            onClick={handleResetAll}
          >
            Reset All
          </Button>
        </Box>
        {/* </Card> */}
      </Box>
      {/* </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}></Box>
      </MobileView> */}
    </>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: "none",
            position: "fixed",
            top: "0px",
            [theme.breakpoints.up("md")]: {
              top: "88px",
              height: "calc(100vh - 88px)",
            },
            // [theme.breakpoints.up("md")]: {
            //   marginTop: "88px",
            // },
            // position: "static",
            overflowX: "hidden", // Hides horizontal scrollbar if content overflows
            "&::-webkit-scrollbar": {
              display: "none", // Hides the scrollbar in webkit browsers (e.g., Chrome, Safari)
            },
            "-ms-overflow-style": "none", // Hides the scrollbar in Internet Explorer and Edge
            scrollbarWidth: "none", // Hides the scrollbar in Firefox
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
