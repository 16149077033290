import { API } from "./utils";

export const signInUser = async (userInfo) => {
  try {
    const formdata = new FormData();
    formdata.append("name", userInfo.name);
    formdata.append("email", userInfo.email);
    formdata.append("profile_pic", userInfo.profile_pic);
    formdata.append("google_id", userInfo.google_id);
    const { data } = await API.post(`/customers/signin_user.php`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (data?.status) {
      return { error: null, data };
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An unexpected error occurred.";
    const data = null;
    return { error: errorMessage, data };
  }
};
