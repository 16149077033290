const reducer = (state, action) => {
  let id;
  switch (action.type) {
    case "@customization/MENU_OPEN":
      id = action.id;
      return {
        ...state,
        isOpen: [id],
      };
    case "@customization/SET_MENU":
      return {
        ...state,
        opened: action.opened,
      };
    case "@customization/SET_FONT_FAMILY":
      return {
        ...state,
        fontFamily: action.fontFamily,
      };
    case "@customization/SET_BORDER_RADIUS":
      return {
        ...state,
        borderRadius: action.borderRadius,
      };
    default:
      return state;
  }
};

export default reducer;
