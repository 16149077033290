import { useDispatch } from "react-redux";
import { setIsAuthenticate } from "../redux/Auth/AuthSlice";

export const ProtectedRoute = ({ element }) => {
  const dispatch = useDispatch();

  if (!localStorage.getItem("user")) {
    dispatch(setIsAuthenticate({ status: false }));
    console.log("0");
    return element;
  }
  dispatch(setIsAuthenticate({ status: true }));
  console.log("1");
  return element;
};
