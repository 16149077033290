import { Outlet } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import { AppBar, Box, CssBaseline, Toolbar } from "@mui/material";

// project imports
import Header from "./Header";

// assets
// import { IconChevronRight } from "@tabler/icons";
import { useCustomizationContext } from "../../context/Customization/CustomizationContext";

// const drawerWidth = 260;
// borderRadius = 6;
// padding = 20;
// gridspacingwidth = 3;

// styles
const MainViewBusiness = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // borderBottomLeftRadius: "6px",
  // borderBottomRightRadius: "6px",
  // borderTopRightRadius: "6px",
  // borderTopLeftRadius: "6px",
  ...theme.typography.mainContent,
  width: "100%",
  backgroundColor: "#fff",
  marginBottom: "20px",
  [theme.breakpoints.up("md")]: {
    marginLeft: "20px",
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "20px",
    padding: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "10px",
    padding: "16px",
    marginRight: "10px",
  },
  transition: theme.transitions.create(
    "margin",
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }
  ),
}));

// ==============================|| VIEW BUSINESS LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const { opened } = useCustomizationContext();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: opened ? theme.transitions.create("width") : "none",
        }}
      >
        <Toolbar>
          <Header />
        </Toolbar>
      </AppBar>

      {/* main content */}
      <Box sx={{ marginTop: "80px", backgroundColor: "#fff", width: "100%" }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
