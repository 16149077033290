import * as api from "../api/BusinessApi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchBusiness = createAsyncThunk(
  "business/getBusiness",
  async (a, thunkAPI) => {
    try {
      const { filters } = thunkAPI.getState()?.business;
      const { error, data } = await api.getBusiness(filters);
      if (error) {
        return thunkAPI.rejectWithValue(error);
      }
      return data?.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchBusinessDetailsById = createAsyncThunk(
  "business/getBusinessById",
  async (id, thunkAPI) => {
    try {
      const { error, data } = await api.getBusinessById(id);
      if (error) {
        return thunkAPI.rejectWithValue(error);
      }
      return data?.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchGenres = createAsyncThunk(
  "business/getGenres",
  async (thunkAPI) => {
    try {
      const { error, data } = await api.getGenres();
      if (error) {
        return thunkAPI.rejectWithValue(error);
      }
      return data?.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchSuggestion = createAsyncThunk(
  "business/getSuggestion",
  async (searchQuery, thunkAPI) => {
    try {
      const { error, data } = await api.getSuggestion(searchQuery);
      if (error) {
        return thunkAPI.rejectWithValue(error);
      }
      return data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchRelevantBusiness = createAsyncThunk(
  "business/getRelevantBusiness",
  async (details, thunkAPI) => {
    try {
      console.log();
      const { error, data } = await api.getRelevantBusiness(details);
      if (error) {
        return thunkAPI.rejectWithValue(error);
      }
      return data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  businessList: Array(32).fill(null),
  isLoading: false,
  filters: [
    { business_type: [] },
    { distance_type: [], longitude: null, latitude: null },
    { rating: 0 },
    { search_id: "" },
    { genre_id: null },
  ],
  page: 1,
  genre: [],
  busisessDetails: [],
  businessService: Array(4).fill(null),
  upcomingService: Array(4).fill(null),
  relevantBusiness: Array(10).fill(null),
  isLoadingBusinessDetails: false,
  searchSuggestion: Array(7).fill(null),
};

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    // action creaters
    setFilters(state, action) {
      state.filters[0] = action.payload[0];
      state.filters[1] = action.payload[1];
      state.filters[2] = action.payload[2];
    },
    setFiltersSearch(state, action) {
      state.filters[3] = action.payload;
    },
    setFiltersGenre(state, action) {
      state.filters[4].genre_id = action.payload;
    },
    setLocationDistance(state, action) {
      state.filters[1] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Business List
      .addCase(fetchBusiness.pending, (state) => {
        state.isLoading = true;
        state.businessList = Array(32).fill(null);
      })
      .addCase(fetchBusiness.fulfilled, (state, action) => {
        state.isLoading = false;
        state.businessList = action.payload;
      })
      .addCase(fetchBusiness.rejected, (state) => {
        state.isLoading = false;
        state.businessList = [];
      })
      // Business Details
      .addCase(fetchBusinessDetailsById.pending, (state) => {
        state.isLoadingBusinessDetails = true;
      })
      .addCase(fetchBusinessDetailsById.fulfilled, (state, action) => {
        state.isLoadingBusinessDetails = false;
        state.busisessDetails = action.payload;
        state.businessService = action.payload?.products;
        state.upcomingService = action.payload?.upcoming_products;
        // state.relevantBusiness = [];
      })
      .addCase(fetchBusinessDetailsById.rejected, (state) => {
        state.isLoadingBusinessDetails = false;
        state.busisessDetails = [];
        state.businessService = [];
        state.upcomingService = [];
        state.relevantBusiness = [];
      })
      // Genre
      .addCase(fetchGenres.pending, (state) => {
        state.genre = [];
      })
      .addCase(fetchGenres.fulfilled, (state, action) => {
        state.genre = action.payload;
      })
      .addCase(fetchGenres.rejected, (state) => {
        state.genre = [];
      })
      // Search Suggestion
      .addCase(fetchSuggestion.pending, (state) => {
        state.searchSuggestion = Array(7).fill(null);
      })
      .addCase(fetchSuggestion.fulfilled, (state, action) => {
        state.searchSuggestion = action.payload;
      })
      .addCase(fetchSuggestion.rejected, (state) => {
        state.searchSuggestion = [];
      })
      // Fetch Relevant Business
      .addCase(fetchRelevantBusiness.pending, (state) => {
        state.relevantBusiness = Array(10).fill(null);
      })
      .addCase(fetchRelevantBusiness.fulfilled, (state, action) => {
        state.relevantBusiness = action.payload?.data;
      })
      .addCase(fetchRelevantBusiness.rejected, (state) => {
        state.relevantBusiness = [];
      });
  },
});

export const {
  setLoading,
  setFilters,
  setFiltersSearch,
  setFiltersGenre,
  setLocationDistance,
} = businessSlice.actions;
export default businessSlice.reducer;
