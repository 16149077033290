import ThemeCustomization from "./themes/index.js";
import { CustomizationContextProvider } from "./context/Customization/CustomizationContext.js";
import Routes from "./routes";
function App() {
  return (
    <>
      <CustomizationContextProvider>
        <ThemeCustomization>
          <Routes />
        </ThemeCustomization>
      </CustomizationContextProvider>
    </>
  );
}

export default App;
