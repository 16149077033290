import { configureStore } from "@reduxjs/toolkit";
import BusinessSlice from "./Business/BusinessSlice";
import AuthSlice from "./Auth/AuthSlice";
import ServiceSlice from "./ServiceSlice/ServiceSlice";
import CustomerSlice from "./ActByCustomer/CustomerSlice";

export const store = configureStore({
  reducer: {
    business: BusinessSlice,
    auth: AuthSlice,
    service: ServiceSlice,
    customer: CustomerSlice,
  },
});
