import * as api from "../api/ServiceApi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchServiceDetailsById = createAsyncThunk(
  "service/getServiceById",
  async (id, thunkAPI) => {
    try {
      const { error, data } = await api.getServiceById(id);
      if (error) {
        return thunkAPI.rejectWithValue(error);
      }
      return data?.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchRelatedService = createAsyncThunk(
  "service/getRelatedService",
  async (id, thunkAPI) => {
    try {
      const { error, data } = await api.getReletedService(id);
      if (error) {
        return thunkAPI.rejectWithValue(error);
      }
      return data?.data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  serviceDetails: {},
  isLoadingServiceDetails: false,
  relevantService: Array(10).fill(null),
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    // action creaters
  },
  extraReducers: (builder) => {
    builder
      // Service Details
      .addCase(fetchServiceDetailsById.pending, (state) => {
        state.isLoadingServiceDetails = true;
      })
      .addCase(fetchServiceDetailsById.fulfilled, (state, action) => {
        state.isLoadingServiceDetails = false;
        state.serviceDetails = action.payload;
      })
      .addCase(fetchServiceDetailsById.rejected, (state) => {
        state.isLoadingServiceDetails = false;
        state.busisessDetails = [];
        state.relevantService = [];
      })
      .addCase(fetchRelatedService.pending, (state) => {
        state.relevantService = Array(10).fill(null);
      })
      .addCase(fetchRelatedService.fulfilled, (state, action) => {
        state.relevantService = action.payload;
      })
      .addCase(fetchRelatedService.rejected, (state) => {
        state.relevantService = [];
      });
  },
});

export default serviceSlice.reducer;
