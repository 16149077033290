import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/DekhoMarket-01.png";
import { useTheme } from "@emotion/react";

const Footer = () => {
  const theme = useTheme();
  return (
    <Box
      mt={2}
      py={4}
      px={5}
      //   sx={{ backgroundColor: "rgba(152,185,255,1)", color: "#fff" }}
      //   sx={{ backgroundColor: "#162d3c", color: "#fff" }}
      sx={{ backgroundColor: "rgb(238, 242, 246)" }}
    >
      <Grid container mb={2}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Box>
            <Link to={"/"}>
              <img
                src={logo}
                alt=""
                style={{
                  width: "150px",
                  height: "100%",
                  objectFit: "cover",
                  cursor: "pointer",
                  [theme.breakpoints.down("sm")]: {
                    width: "120px",
                  },
                }}
              />
            </Link>

            <Typography variant="subtitle1" mt={2}>
              Corporate Office 401, 4th Floor A-82, Sector-63, Noida, UP-201301
              +917428858436 info@hellomistri.com
            </Typography>
            <Box
              mt={2}
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <Link
                to={"https://www.facebook.com/Hellomistripvtltd/"}
                target="_blanck"
              >
                <img
                  width="48"
                  height="48"
                  src="https://img.icons8.com/color/48/facebook.png"
                  alt="facebook"
                  style={{ marginRight: "10px" }}
                />
              </Link>
              <Link
                to={"https://www.instagram.com/hello_mistri_pvt_ltd/"}
                target="_blanck"
              >
                <img
                  width="48"
                  height="48"
                  src="https://img.icons8.com/fluency/48/instagram-new.png"
                  alt="instagram-new"
                  style={{ marginRight: "10px" }}
                />
              </Link>
              <Link to={"https://twitter.com/Hello_Mistri"} target="_blanck">
                <img
                  width="48"
                  height="48"
                  src="https://img.icons8.com/fluency/48/twitterx--v1.png"
                  alt="twitterx--v1"
                  //   style={{ marginRight: "10px" }}
                />
              </Link>
              {/* <Link to={"https://twitter.com/Hello_Mistri"} target="_blanck">
                <img
                  width="48"
                  height="48"
                  src="https://img.icons8.com/color/48/youtube-play.png"
                  alt="youtube-play"
                />
              </Link> */}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            flexDirection={"column"}
            sx={{
              [theme.breakpoints.down("md")]: {
                marginTop: "16px",
              },
            }}
          >
            <Typography variant="h4">Applications</Typography>
            <Typography variant="subtitle1" mt={2}>
              Dekho Market Provider
            </Typography>
            <Typography variant="subtitle1" mt={2}>
              Dekho Market Customer
            </Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={4} md={6} sm={12}>
          <Box
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Typography variant="h4">Site Links</Typography>
          </Box>
        </Grid> */}
      </Grid>
      <Divider
        sx={{
          borderWidth: "1px 0px 0px",
          borderTopColor: "rgb(207, 207, 207)",
        }}
      />
      <Box
        mt={1.5}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
      >
        <Typography variant="subtitle1" mt={2}>
          © 2024 Dekho Market All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
