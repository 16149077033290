import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Stack,
  Typography,
  Backdrop,
  Popover,
  CircularProgress,
  circularProgressClasses,
} from "@mui/material";

import { IconLogout, IconSettings, IconUser } from "@tabler/icons";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import CloseIcon from "@mui/icons-material/Close";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  signIn,
  setOpenLoginModal,
  setCloseLoginModal,
} from "../../../../redux/Auth/AuthSlice";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [changeModalContent, setChangeModalContent] = useState(true);

  const { user, isLoginLoading, openLoginModal } = useSelector(
    (store) => store.auth
  );

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Login Modal Handle
  const handleOpenLoginModal = () => dispatch(setOpenLoginModal());
  const handleCloseLoginModal = () => dispatch(setCloseLoginModal());

  // Google Login
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);
      console.log(userInfo);
      if (userInfo) {
        const user = {
          name: userInfo.name,
          email: userInfo.email,
          profile_pic: userInfo.picture,
          google_id: "",
        };
        console.log(user);
        dispatch(signIn(user));
      }
    },
  });

  // logout
  const logoutFun = () => {
    dispatch(logout());
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "25px 30px",
    },
    bgcolor: "background.paper",
    borderRadius: "6px",
    boxShadow: 24,
    p: 3,
    padding: "25px 45px",
  };

  const currentTime = new Date().getHours();

  let greeting;
  if (currentTime >= 5 && currentTime < 12) {
    greeting = "Good Morning";
  } else if (currentTime >= 12 && currentTime < 17) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }

  return (
    <>
      {user !== null ? (   // ihave changed 
        // <Chip
        //   sx={{
        //     height: "48px",
        //     alignItems: "center",
        //     borderRadius: "27px",
        //     transition: "all .2s ease-in-out",
        //     borderColor: theme.palette.primary.light,
        //     backgroundColor: theme.palette.primary.light,
        //     cursor: "pointer",
        //     '&[aria-controls="menu-list-grow"], &:hover': {
        //       borderColor: theme.palette.primary.main,
        //       background: `${theme.palette.primary.main}!important`,
        //       color: theme.palette.primary.light,
        //       "& svg": {
        //         stroke: theme.palette.primary.light,
        //       },
        //     },
        //     "& .MuiChip-label": {
        //       lineHeight: 0,
        //       paddingLeft: "5px !important",
        //     },
        //   }}
        //   variant="outlined"
        //   ref={anchorRef}
        //   // aria-controls={open ? "menu-list-grow" : undefined}
        //   aria-haspopup="true"
        //   // onClick={handleToggle}
        //   color="primary"
        //   icon={<Avatar alt={user?.first_name} src={user?.profile_image} />}
        // ></Chip>
        <>
          <Avatar
            alt={user?.first_name}
            src={user?.profile_image}
            sx={{ cursor: "pointer" }}
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Paper>
              <Box sx={{ p: 2 }}>
                <Stack>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Typography variant="h4">{greeting},</Typography>
                    <Typography
                      component="span"
                      variant="h4"
                      sx={{ fontWeight: 400 }}
                    >
                      {user?.first_name?.substring(0, 8)}
                      {user?.last_name?.substring(0, 8)}
                    </Typography>
                  </Stack>
                  <Typography variant="subtitle2" mt={1}>
                    {user?.email.split("@")[0].slice(0, 2) +
                      ".....@" +
                      user?.email.split("@")[1]}
                  </Typography>
                </Stack>
              </Box>

              <Box sx={{ p: 2 }}>
                <Divider />
                <List
                  component="nav"
                  sx={{
                    width: "100%",
                    maxWidth: 350,
                    minWidth: 300,
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: "10px",
                    [theme.breakpoints.down("md")]: {
                      minWidth: "100%",
                    },
                    "& .MuiListItemButton-root": {
                      mt: 0.5,
                    },
                  }}
                >
                  <ListItemButton
                    sx={{
                      borderRadius: `12px`,
                    }}
                    onClick={() => {
                      navigate("/businesses/viewbusiness/manageservice");
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      {/* <IconSettings stroke={1.5} size="1.3rem" /> */}

                      <ShoppingCartCheckoutIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="body2">
                          Manage Booking List
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  {/* <ListItemButton
                    sx={{
                      borderRadius: `12px`,
                    }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <IconUser stroke={1.5} size="1.3rem" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Grid
                          container
                          spacing={1}
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography variant="body2">
                              Social Profile
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Chip
                              label="02"
                              size="small"
                              sx={{
                                bgcolor: theme.palette.warning.dark,
                                color: theme.palette.background.default,
                              }}
                            />
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItemButton> */}
                  <ListItemButton
                    sx={{
                      borderRadius: `12px`,
                    }}
                    onClick={() => {
                      logoutFun();
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <IconLogout stroke={1.5} size="1.3rem" />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography variant="body2">Logout</Typography>}
                    />
                  </ListItemButton>
                </List>
              </Box>
            </Paper>
          </Popover>
        </>
      ) : (
        <Chip
          sx={{
            height: "48px",
            alignItems: "center",
            borderRadius: "27px",
            transition: "all .2s ease-in-out",
            borderColor: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.light,
            cursor: "pointer",
            '&[aria-controls="menu-list-grow"], &:hover': {
              borderColor: theme.palette.primary.main,
              background: `${theme.palette.primary.main}!important`,
              color: theme.palette.primary.light,
              "& svg": {
                stroke: theme.palette.primary.light,
              },
            },
            "& .MuiChip-label": {
              lineHeight: 0,
              paddingLeft: "5px !important",
            },
          }}
          icon={
            <Avatar
              // src={User1}
              src={""}
              sx={{
                ...theme.typography.mediumAvatar,
                margin: "8px 0 8px 8px !important",
              }}
              color="inherit"
            />
          }
          label={<Typography>Login</Typography>}
          variant="outlined"
          // onClick={handleToggle}
          color="primary"
          // onClick={() => loginWithRedirect()}
          onClick={handleOpenLoginModal}
        />
      )}

      {/* Login Modal */}
      <Modal
        open={openLoginModal}
        onClose={handleCloseLoginModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          sx: { backdropFilter: "blur(5px)" }, // Apply blur effect to the backdrop
        }}
      >
        <Box
          width={"100%"}
          sx={style}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          component="form"
        >
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <CloseIcon
              onClick={handleCloseLoginModal}
              sx={{ cursor: "pointer" }}
            />
          </Box>
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              fontSize={"2rem"}
              fontWeight={"400"}
              sx={{ textTransform: "none" }}
            >
              {changeModalContent ? "Welcome back." : "Join Dekho Market."}
            </Typography>
          </Box>
          <Box
            sx={{ mt: 3 }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
          >
            {/* <GoogleLogin
              onSuccess={(credentialResponse) => {
                console.log(credentialResponse);
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            /> */}
            <Button
              onClick={() => login()}
              sx={{
                padding: "10px 20px",
                border: "1px solid black",
                borderRadius: "30px",
                color: "black",
                backgroundColor: "#fff",
                "&:hover": {
                  backgroundColor: "#fff",
                },
              }}
            >
              {isLoginLoading ? (
                <>
                  <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                      marginRight: "24px",
                      color: (theme) =>
                        theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
                      animationDuration: "550ms",

                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                      },
                    }}
                    size={35}
                    thickness={4}
                  />
                  {changeModalContent
                    ? "Sign in with Google"
                    : "Sign up with Google"}
                </>
              ) : (
                <>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    class="ah"
                    style={{ marginRight: "24px" }}
                  >
                    <g fill-rule="evenodd" clip-rule="evenodd">
                      <path
                        d="M20.64 12.2c0-.63-.06-1.25-.16-1.84H12v3.49h4.84a4.14 4.14 0 0 1-1.8 2.71v2.26h2.92a8.78 8.78 0 0 0 2.68-6.61z"
                        fill="#4285F4"
                      ></path>
                      <path
                        d="M12 21a8.6 8.6 0 0 0 5.96-2.18l-2.91-2.26a5.41 5.41 0 0 1-8.09-2.85h-3v2.33A9 9 0 0 0 12 21z"
                        fill="#34A853"
                      ></path>
                      <path
                        d="M6.96 13.71a5.41 5.41 0 0 1 0-3.42V7.96h-3a9 9 0 0 0 0 8.08l3-2.33z"
                        fill="#FBBC05"
                      ></path>
                      <path
                        d="M12 6.58c1.32 0 2.5.45 3.44 1.35l2.58-2.58A9 9 0 0 0 3.96 7.96l3 2.33A5.36 5.36 0 0 1 12 6.6z"
                        fill="#EA4335"
                      ></path>
                    </g>
                  </svg>
                  {changeModalContent
                    ? "Sign in with Google"
                    : "Sign up with Google"}
                </>
              )}
            </Button>
          </Box>
          <Box
            sx={{ mt: 4 }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
          >
            <Typography variant="subtitle1" mr={1}>
              {changeModalContent ? "No account?" : "Already have an account?"}
            </Typography>
            <Typography
              variant="h4"
              color="primary"
              sx={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                setChangeModalContent(!changeModalContent);
              }}
            >
              {changeModalContent ? "Create One" : "Sign in"}
            </Typography>
          </Box>
          <Box
            sx={{ mt: 5, mb: 2 }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
          >
            <Typography variant="caption" mr={1} sx={{ textAlign: "center" }}>
              Click “Sign in” to agree to Dekho Market{" "}
              <span style={{ textDecoration: "underline" }}>
                Terms of Service
              </span>{" "}
              and acknowledge that Dekho Market{" "}
              <span style={{ textDecoration: "underline" }}>
                Privacy Policy
              </span>{" "}
              applies to you.
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ProfileSection;
